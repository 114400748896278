import React from "react";
import RecentTransaction from "./components/RecentTransaction";
import AllocationPosition from "./components/AllocationPosition";
import PerformanceOverview from "./components/PerformanceOverview";
import SettingControls from "./components/SettingControls";
import { Container, Row, Col } from 'react-bootstrap';
import AiPerformance from "./components/AiPerformance";

const Portfolio = () => {
    return (
        <>
            <Container fluid className="mb-3">
                <Row>
                    
                    <Col md={6} lg={3}>
                        <PerformanceOverview />
                        <AllocationPosition />
                    </Col>
                    <Col md={6}>
                        <AiPerformance />
                        <RecentTransaction />
                    </Col>
                    <Col md={6} lg={3}>
                        <SettingControls />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Portfolio;