import React, { useEffect, useRef, useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from "axios";

const TradingChart = () => {
  const containerRef = useRef(null);
  const { trading_live_data, selectedValue, setSelectedValue ,getLiveMarketData, getHistoricalMarketData, getPriceChange, bid_ask_data, setCurrentPrice,setLatestConid, finalData, setFinalData, setSymbolData, getOlChange, sethighPrice, setlowPrice} = useContext(AuthContext);
  let lastBar;
  const [allSymbols] = useState([
    { symbol: 'ZS-Jan25', full_name: 'Soybean', exchange: 'CBOT', type: 'commodity', conid: '597391665'}
  ])
  let new_symbol_data = [
    { symbol: 'ZS-Jan25', full_name: 'Soybean', exchange: 'CBOT', type: 'commodity', conid: '597391665'}
  ];
  
  const getSchemaForResolution = (resolution) => {
    switch (resolution) {
      case '1s':
        return '1s';
      case '1':
        return '1min';
      case '60':
        return '1h';
      case '1D':
        return '1d';
      case '1W':
        return '1w';
      case '1M':
        return '1m';
      case '6M':
        return '6m';
      case '1Y':
        return '1y';
      default:
        return '1m';
    }
  };

  const getTimeFrameForResolution = (resolution) => {
    switch (resolution) {
      case '1s':
        return '1min';
      case '1':
        return '1d';
      case '5':
        return '5d';
      case '30':
        return '1m';
      case '60':
        return '3m';
      case '120':
        return '6m';
      case '1D':
        return '1y';  // Resolves to 1 year
      case '1W':
        return '5y';  // For '5y', return '5y'
      case '1M':
        return '6y';
      default:
        return '1m';
    }
  };
  
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${year}${month}${day}-${hours}:${minutes}:${seconds}`;
  };

  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);  // Extracts the year part (e.g., "2024")
    const month = dateString.substring(4, 6); // Extracts the month part (e.g., "12")
    const day = dateString.substring(6, 8);   // Extracts the day part (e.g., "27")
  
    // Create a new Date object
    const date = new Date(`${year}-${month}-${day}`);
  
    // Format the month as a 3-letter abbreviation
    const monthAbbreviation = date.toLocaleString('en-US', { month: 'short' });
  
    // Extract the last 2 digits of the year
    const yearShort = year.substring(2);
  
    // Return the formatted date in the "Dec24" format
    return `${monthAbbreviation}${yearShort}`;
  };

  async function processApiData(apiData) {
    
    
    // Ensure apiData is an array before proceeding
    if (!Array.isArray(apiData)) {
      // console.error("apiData is not an array:", apiData);
      return;  // Exit early if apiData is not iterable
    }
  
    // Loop through each record in apiData
    for (const record of apiData) {
      const { symbol, futuresData } = record;
  
      // Check if the record has futuresData
      if (futuresData) {
        // Loop through each key in futuresData (e.g., GC, CL, SI, etc.)
        for (const futuresKey of Object.keys(futuresData)) {
          const futures = futuresData[futuresKey];
  
          // Loop through each future in the specific futures category (e.g., GC, CL, SI)
          for (const future of futures) {
            const { expirationDate, symbol, conid } = future;
            const formattedDate = formatDate(String(expirationDate));
            // Generate the symbol combining expirationDate and symbol
            const symbolWithExpiration = `${symbol}-${formattedDate}`;
  
            // Add to the symbols array
            new_symbol_data.push({
              symbol: symbolWithExpiration,
              full_name: record.companyHeader,
              exchange: record.description,
              conid: conid
            });
            // console.log("if data:", symbolWithExpiration);  // Log the symbol with expiration
          }
        }
      } else if (symbol) {
        // For other records, just push them into symbols if they have a symbol
        new_symbol_data.push({
          symbol: symbol,
          full_name: record.companyHeader,
          exchange: record.description,
          conid: record.conid
        });
        // console.log("else data:", symbol);  // Log the symbol
      }
    }
  
    // Log new_symbol_data after the loop completes
    // console.log("new_symbol_data:", new_symbol_data);
    // setAllSymbols(new_symbol_data)
    // console.log(allSymbols, "allSymbols:");
    return new_symbol_data
  }  

  const searchNewSymbol = async (symbol) => {
    try {
      const symbolData = {
        symbol: symbol,
        pattern: true,
        referrer: ""
      };
      const response = await axios.post(
        "https://ibkrapi.nodojicommodities.com/custom-apis/getSymbolDetailsPost",
        symbolData
      );
      
      return response.data;
    } catch (error) {
      console.error("Error fetching symbol data:", error);
    }
  };

  // Custom datafeed for TradingView
  class CustomDatafeed {
    constructor(fetchLiveData, fetchHistoricalData, fetchLiveMarketData, fetchPriceChange, fetchBidNAsk, fetchOlChange) {
      this.fetchLiveData = fetchLiveData;
      this.fetchHistoricalData = fetchHistoricalData;
      this.fetchLiveMarketData = fetchLiveMarketData;
      this.fetchPriceChange = fetchPriceChange;
      this.fetchBidNAsk = fetchBidNAsk;
      this.fetchOlChange = fetchOlChange;
      this.subscribers = {};
      this.cachedBars = {}; 
      this.isHistoricalDataFetched = false;
      this.previousResolution = null;
      this.symbols = allSymbols;
    }
  
    onReady(callback) {
      setTimeout(() => callback({
        supported_resolutions:  ["1", "1H", "1D", "5D", "1W", "1M", "6M", "1Y"],
        supports_marks: false,
        supports_timescale_marks: false,
        supports_time: true,
        supports_search: true,
      }), 0);
    }

    async getMatchingSymbolAndIndex(symbolInfo) {
      // Get the symbol prefix (e.g., 'ZS' from 'ZS-Jan25')
      const symbolPrefix = symbolInfo.name.split('-')[0];
    
      // Filter symbols that start with the same prefix
      const matchingSymbols = this.symbols.filter(symbol => symbol.symbol.startsWith(symbolPrefix));
    
      // Extract months dynamically from the matching symbols
      const symbolMonths = matchingSymbols.map(symbol => {
        const symbolYearMonth = symbol.symbol.split('-')[1]; // Split to get month-year part (e.g., 'Jan25')
        return symbolYearMonth;
      });
    
      // Remove duplicates by converting the array to a Set and back to an array
      const uniqueMonths = [...new Set(symbolMonths)];
    
      // Extract the month part from the symbolInfo.name (e.g., 'Jan25' from 'ZS-Jan25')
      const symbolYearMonth = symbolInfo.name.split('-')[1];
      const symbolMonth = symbolYearMonth.substring(0, 3); // Extracts 'Jan' from 'Jan25'
    
      // Find the matching month in the unique months list
      const matchingMonth = uniqueMonths.find(month => month.startsWith(symbolMonth));
    
      if (!matchingMonth) {
        console.error(`No matching month found for '${symbolMonth}' in the symbols list.`);
        return { matchingSymbol: null, symbolIndexInList: -1 }; // Return -1 if no matching month is found
      }
    
      // Find the matching symbol in the filtered list where the month part matches symbolMonth
      const matchingSymbol = matchingSymbols.find(symbol => {
        const symbolYearMonth = symbol.symbol.split('-')[1]; // Split to get month-year part
        const symbolMonthInList = symbolYearMonth.substring(0, 3); // Extracts 'Jan' from 'Jan25'
        return symbolMonthInList === symbolMonth;
      });
    
      if (matchingSymbol) {
        // Find index of the matching symbol in matchingSymbols
        const symbolIndexInList = matchingSymbols.findIndex(symbol => symbol.symbol === matchingSymbol.symbol);
        // console.log(`Found matching symbol '${matchingSymbol.symbol}' at index ${symbolIndexInList}`);
        return { matchingSymbol, symbolIndexInList };
      } else {
        console.error(`No matching symbol found for month '${symbolMonth}' in the prefix '${symbolPrefix}' symbols.`);
        return { matchingSymbol: null, symbolIndexInList: -1 }; // Return -1 if no matching symbol was found
      }
    }
  
    resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
      const [symbol] = symbolName.split('-');
      setSelectedValue(symbolName);
      const specificSymbolNew = finalData.find(item => item.symbol === symbolName);
      
      const symbolInfo = {
        ticker: symbol,
        name: symbolName,
        type: 'commodity',
        session: '24x7',
        timezone: 'America/New_York',
        exchange: specificSymbolNew?.exchange,
        minmov: 1,
        pricescale: 100,
        has_intraday: true,
        has_daily: true,
        has_weekly_and_monthly : true,
        supported_resolutions:  ["1", "1H", "1D", "5D", "1W", "1M", "6M", "1Y"],
        volume_precision: 2,
        data_status: 'streaming',
        minmov2: 0,
        pointvalue: 1,
        format: 'price',
        visible_plots_set: "C",
        description: specificSymbolNew?.exchange,
      };
      setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
    }
  
    async getBars(symbolInfo, resolution, from, onHistoryCallback, onErrorCallback) {
      try {
        const period = getTimeFrameForResolution(resolution);
        setSymbolData(symbolInfo.name);

        if(from.firstDataRequest === false){
          this.isHistoricalDataFetched = false;
          const lastBarDate = new Date(lastBar.time);
          lastBarDate.setHours(1, 1, 0, 0); // Set time to "01:01:00"
          const formattedFrom = Math.floor(lastBarDate.getTime() / 1000); 
          from.from = formattedFrom;
        }
        
        // Check if resolution has changed
        if (this.previousResolution !== resolution) {
          // console.log(`Resolution changed from ${this.previousResolution} to ${resolution}`);
          this.isHistoricalDataFetched = false;  // Reset flag to re-fetch data
          this.previousResolution = resolution;  // Update the previous resolution
        }
    
        const cacheKey = `${symbolInfo.name}_${resolution}_${from.from}_${from.to}`;
        if (this.cachedBars[cacheKey]) {
          // console.log('Returning cached bars for:', cacheKey);
          onHistoryCallback(this.cachedBars[cacheKey], { noData: false });
          return;
        }
    
        if (this.isHistoricalDataFetched) {
          // console.log('Returning cached data due to resolution not changing');
          onHistoryCallback(this.cachedBars[cacheKey] || [], { noData: false });
          return;
        }
    
        const formattedDate = formatTimestamp(from.from);
        // console.log(currentSymbol);
        const specificSymbol = finalData.find(item => item.symbol === symbolInfo.name);
        setLatestConid(specificSymbol?.conid);
        this.isHistoricalDataFetched = true;
        const schema = getSchemaForResolution(resolution);
        let response;
        // Pass formattedDate as startTime if isRetrigger is true
        if (from.firstDataRequest === false) {
          // console.log('Passing formattedDate as startTime because isRetrigger is true');
          response = await this.fetchHistoricalData(schema, period, specificSymbol?.conid, formattedDate);
        } else {
          // console.log('No need to pass formattedDate as startTime');
          console.log('getBars fetch');
          response = await this.fetchHistoricalData(schema, period, specificSymbol?.conid);
        }
    
        // console.log(response);
        
        if (!response || !response.data || response.data.length === 0) {
          console.log('No historical data available');
          onHistoryCallback([], { noData: true });
          return;
        }
    
        let bars = response.data.map(entry => ({
          time: entry.t,
          open: entry.o, 
          high: entry.h,
          low: entry.l,
          close: entry.c,
          volume: entry.v
        }));
    
        // console.log(bars);
    
        // Prepend the new data to the existing cached data (if any)
        if (this.cachedBars[cacheKey]) {
          bars = [...this.cachedBars[cacheKey], ...bars]; // Prepend new bars to existing bars
        }
    
        lastBar = bars[0];
        // console.log('Last bar time:', lastBar?.time);
    
        this.cachedBars[cacheKey] = bars;
    
        // Pass the new and existing data to the callback
        onHistoryCallback(bars, { noData: false });
        console.log(onHistoryCallback);
        
      } catch (error) {
        console.error('Error fetching historical data:', error);
        onErrorCallback(error.message);
      }
    }
    

    async subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
      if (this.subscribers[subscriberUID]) {
        this.unsubscribeBars(subscriberUID); // Unsubscribe from the previous symbol
      }
    
      // const { matchingSymbol, symbolIndexInList } = await this.getMatchingSymbolAndIndex(symbolInfo);


      // if (symbolIndexInList === -1 || !matchingSymbol) {
        // return; // Exit if no matching symbol is found
      // }
    
      // console.log(symbolIndexInList);
      const specificSymbol = finalData.find(item => item.symbol === symbolInfo.name);

      await this.fetchLiveMarketData(specificSymbol?.conid);
      await this.fetchPriceChange(specificSymbol?.conid);
      await this.fetchOlChange(specificSymbol?.conid);
      this.fetchBidNAsk(symbolInfo.name);
    
      // Fetch live data every 5 seconds
      const fetchAndUpdate = async () => {
        try {
          
          const response = await this.fetchLiveData(symbolInfo.name);
    
          if (response && response.data) {
            const data = response.data;
            if (data.length > 0) {
              const newBar = {
                time: data[data.length - 1].time / 1000000, // Convert nanoseconds to milliseconds
                open: data[data.length - 1].open / 1000000000,
                high: data[data.length - 1].high / 1000000000,
                low: data[data.length - 1].low / 1000000000,
                close: data[data.length - 1].close / 1000000000,
                volume: data[data.length - 1].volume,
              };
    
              setCurrentPrice(newBar.close);
              sethighPrice(newBar.high);
              setlowPrice(newBar.low);
              // Call the callback to update chart with new data
              onRealtimeCallback(newBar);
            } else {
              console.log('No new data available for subscribeBars');
            }
          } else {
            console.log('Invalid or empty response data in subscribeBars');
          }
        } catch (error) {
          console.error('Error fetching live data:', error);
        }
      };
    
      // Fetch live data every 3 seconds
      const interval = setInterval(fetchAndUpdate, 3000);
      this.subscribers[subscriberUID] = interval;
    }
    
  
    unsubscribeBars(subscriberUID) {
      if (this.subscribers[subscriberUID]) {
        clearInterval(this.subscribers[subscriberUID]);
        delete this.subscribers[subscriberUID];
      }
    }

    async searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
      // console.log(userInput, "userInput");
      // console.log(exchange, "exchange");
      // console.log(symbolType, "symbolType");

      // Await the completion of searchNewSymbol
      let new_data = await searchNewSymbol(userInput);  // Ensure we wait for this to finish
      let updated_data = await processApiData(new_data);
      let final_data = this.symbols;
      // console.log("new_data:", new_data);
      // console.log("updated_data:", updated_data);
      if (updated_data !== undefined) {
          final_data = updated_data
      }
      setFinalData(final_data)
      // console.log('final_data', final_data);

      // Filter symbols after data has been fetched
      const filteredSymbols = final_data.filter(symbol => {
          const matchesInput = symbol.full_name.toLowerCase().includes(userInput.toLowerCase()) ||
                              symbol.symbol.toLowerCase().includes(userInput.toLowerCase());
          const matchesExchange = !exchange || symbol.exchange === exchange;
          const matchesType = !symbolType || symbol.type === symbolType;
          return matchesInput && matchesExchange && matchesType;
      });
      // console.log(filteredSymbols, "filteredSymbols");

      const searchResults = filteredSymbols.map(symbol => ({
          symbol: symbol.symbol,
          full_name: symbol.full_name,
          description: symbol.full_name,
          exchange: symbol.exchange,
          type: symbol.type,
      }));

      // console.log(searchResults);

      if (searchResults.length > 0) {
          // const selectedSymbol = searchResults[0].symbol;
          // setSelectedValue(searchResults[0].symbol);
          this.isHistoricalDataFetched = false;
      }

      onResultReadyCallback(searchResults);
    } 
  }  

  useEffect(() => {
    if (!window.TradingView) {
      console.error('TradingView library not loaded!');
      return;
    }

    const widgetOptions = {
      container: containerRef.current,
      library_path: '/charting_library/',
      datafeed: new CustomDatafeed(trading_live_data, getHistoricalMarketData, getLiveMarketData, getPriceChange, bid_ask_data, getOlChange),
      symbol: selectedValue,
      interval: '1',
      locale: 'en',
      fullscreen: true,
      autosize: true,
      theme: 'Dark',
      time_frames: [
        { text: "1D", resolution: "1", description: "1 Day" },
        { text: "5D", resolution: "5", description: "5 Days" },
        { text: "1M", resolution: "30min", description: "1 Month" },
        { text: "3M", resolution: "60", description: "3 Months" },
        { text: "6M", resolution: "120", description: "6 Months" },
        { text: "1y", resolution: "1D", description: "1 Year" },
        { text: "5y", resolution: "1W", description: "5 Years" },
        { text: "1000y", resolution: "1M", description: "All", title: "All" },
      ],
      custom_css_url: "/charting_library/custom_styles.css",
    };

    const tvWidget = new window.TradingView.widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.activeChart().onVisibleRangeChanged().subscribe(null, ({ from, to }) => {
        widgetOptions.datafeed.isHistoricalDataFetched = false;
    
          // Adjust `from` to include "01:01:00" time on the last bar's date
          const lastBarDate = new Date(lastBar.time);
          lastBarDate.setHours(1, 1, 0, 0); // Set time to "01:01:00"
      });
    });

    return () => {
      if (tvWidget) tvWidget.remove();
    };
  }, [selectedValue]);
  

  return <div className='main-card home-chart mt-3' id="trading-chart" ref={containerRef} />;
};

export default TradingChart;