// SettingsDialog.js
import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import UpdateEmail from './UpdateEmail'
import UpdateName from './UpdateName'
import UpdatePhoneNumber from './UpdatePhoneNumber'
import UpdateAddress from './UpdateAddress'
import UpdatePassword from './UpdatePassword'
import { AuthContext } from '../context/AuthContext';

function AccountSettingsDialog({ open, onClose }) {
    const { fetchUserData, userData } = useContext(AuthContext);

    const [openSettings, setOpenSettings] = useState(false);
    const handleOpenSettings = () => setOpenSettings(true);
    const handleCloseSettings = () => setOpenSettings(false);
    const [email, setEmail] = useState("");

    const [openName, setOpenName] = useState(false);
    const handleOpenName = () => setOpenName(true);
    const handleCloseName = () => setOpenName(false);
    const [name, setName] = useState("");

    const [openPhonePhone, setOpenPhone] = useState(false);
    const handleOpenPhone = () => setOpenPhone(true);
    const handleClosePhone = () => setOpenPhone(false);
    const [phone, setPhone] = useState("");

    const [openAddress, setOpenAddress] = useState(false);
    const handleOpenAddress = () => setOpenAddress(true);
    const handleCloseAddress = () => setOpenAddress(false);
    const [address, setAddress] = useState("");

    const [openPassword, setOpenPassword] = useState(false);
    const handleOpenPassword = () => setOpenPassword(true);
    const handleClosePassword = () => setOpenPassword(false);
    const [password] = useState("12345678");

    const maskedPassword = '*'.repeat(password.length);

    useEffect(() => {
      fetchUserData();
    }, [open, fetchUserData]);
    
    useEffect(() => {
      if (userData) {
        setEmail(userData.email || email);
        setName(userData.name || name);
        setPhone(userData.phone_number || phone);
        setAddress(userData.address || address);
      } 
    }, [userData, email, name, phone, address]);

    // Callback to refresh data after updating
    const handleUpdate = async (field, value) => {
      switch (field) {
          case "name":
              setName(value);
              break;
          case "email":
              setEmail(value);
              break;
          case "phone":
              setPhone(value);
              break;
          case "address":
              setAddress(value);
              break;
          default:
              break;
      }
      // Fetch updated user data after any change
    await fetchUserData();
    }; 

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="settings-dialog-title" fullWidth PaperProps={{
      style: {
        width: '480px',
        maxWidth: '100%',
      },
    }} className="setting-dialog">
      <DialogTitle id="settings-dialog-title" className="d-flex justify-content-between mb-2">
        Account Settings
        <img style={{ float: 'none', height: "22px", width: "20px" }} src={`${process.env.PUBLIC_URL}/images/close.png`} alt="close" className="close-icon" role="button" onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Row className="p-0 align-items-center mb-3">
          <Col className="flex-grow-1">
            <h6>Password</h6>
          </Col>
          <Col sm="auto" className="text-end">
            <p className="gray-text-only mb-0">{maskedPassword}</p>
          </Col>
          <Col sm="auto" className="text-end" onClick={handleOpenPassword}>
            <img
              style={{ width: "20px" }}
              src={`${process.env.PUBLIC_URL}/images/right.svg`}
              alt="close"
              className="right-icon"
              role="button"
            />
          </Col>
        </Row>
        <Row className="p-0 align-items-center mb-3">
          <Col className="flex-grow-1">
            <h6>Name</h6>
          </Col>
          <Col sm="auto" className="text-end">
            <p className="gray-text-only mb-0">{name}</p>
          </Col>
          <Col sm="auto" className="text-end" onClick={handleOpenName}>
            <img
              style={{ width: "20px" }}
              src={`${process.env.PUBLIC_URL}/images/right.svg`}
              alt="close"
              className="right-icon"
              role="button"
            />
          </Col>
        </Row>
        <Row className="p-0 align-items-center mb-3">
          <Col className="flex-grow-1">
            <h6>Email Address</h6>
          </Col>
          <Col sm="auto" className="text-end" >
            <p className="gray-text-only mb-0" style={{cursor:'pointer'}}>{email}</p>
          </Col>
          <Col sm="auto" className="text-end" onClick={handleOpenSettings}>
            <img
              style={{ width: "20px" }}
              src={`${process.env.PUBLIC_URL}/images/right.svg`}
              alt="close"
              className="right-icon"
              role="button"
            />
          </Col>
        </Row>
        <Row className="p-0 align-items-center mb-3">
          <Col className="flex-grow-1">
            <h6>Phone Number</h6>
          </Col>
          <Col sm="auto" className="text-end">
            <p className="gray-text-only mb-0">{phone}</p>
          </Col>
          <Col sm="auto" className="text-end" onClick={handleOpenPhone}>
            <img
              style={{ width: "20px" }}
              src={`${process.env.PUBLIC_URL}/images/right.svg`}
              alt="close"
              className="right-icon"
              role="button"
            />
          </Col>
        </Row>
        <Row className="p-0 align-items-center mb-3">
          <Col className="flex-grow-1">
            <h6>Address</h6>
          </Col>
          <Col sm="auto" className="text-end">
            <p className="gray-text-only mb-0">{address.length > 17 ? `${address.substring(0, 17)}...` : address}</p>
          </Col>
          <Col sm="auto" className="text-end" onClick={handleOpenAddress}>
            <img
              style={{ width: "20px" }}
              src={`${process.env.PUBLIC_URL}/images/right.svg`}
              alt="close"
              className="right-icon"
              role="button"
            />
          </Col>
        </Row>
        <Row className="p-0 align-items-center">
          <Col className="flex-grow-1">
            <p className="text-danger mb-3 mt-3">Delete Account</p>
          </Col>
        </Row>
      </DialogContent>

      <UpdateEmail open={openSettings} onClose={handleCloseSettings} onUpdate={handleUpdate} currentemail={email} />
      <UpdateName open={openName} onClose={handleCloseName} onUpdate={handleUpdate} currentname={name} />
      <UpdatePhoneNumber open={openPhonePhone} onClose={handleClosePhone} onUpdate={handleUpdate} currentphone={phone} />
      <UpdateAddress open={openAddress} onClose={handleCloseAddress} onUpdate={handleUpdate} currentaddress={address} />
      <UpdatePassword open={openPassword} onClose={handleClosePassword} currentpassword={password} />

    </Dialog>
  );
}

export default AccountSettingsDialog;
