import React, { useState, useEffect, useContext } from 'react';
import { Card, Table, NavDropdown } from 'react-bootstrap';
import ApexCharts from 'react-apexcharts';
import ProgressBar from './ProgressBar';
import CustomDropdown from './CustomDropdown';
import { useWidgetContext } from '../context/DragContext';
import { AuthContext } from '../context/AuthContext';

function AccountInformation() {
    const [sortOption, setSortOption] = useState('Demo Account');
    const [chartHeight, setChartHeight] = useState(210);
    const { removeWidgetFromGrid } = useWidgetContext();
    const { aioperation, accountInfoData, yourHoldingData } = useContext(AuthContext);
    const [showDropdown, setShowDropdown] = useState(false);
    const [investmentData, setInvestmentData] = useState([]);
    
    const accruedCashAmount = accountInfoData?.summary?.netLiquidationValue|| 0;
    const availableFunds = accountInfoData?.summary?.availableFunds || 0;
    const dailyPnl = accountInfoData?.PnL?.dpl || 0;
    const balance = accruedCashAmount + availableFunds;
    const todayPnlPer = (isNaN(dailyPnl) || isNaN(accruedCashAmount) || accruedCashAmount === 0)
  ? "0"
  : ((dailyPnl / accruedCashAmount) * 100).toFixed(2);


    // console.log(accountInfoData)

    const handleToggle = (isOpen) => {
        setShowDropdown(isOpen);
    };

    const handleSvgClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSortChange = (option) => {
        setSortOption(option);
        console.log(option);
    };

    const updateChartHeight = () => {
        const height = window.innerHeight;
        // Set height based on media query conditions
        if (height <= 800) {
            setChartHeight(70);
        } 
        else if (height <= 880) {
            setChartHeight(120);
        } 
        else if(height>= 900){
            setChartHeight(190);
        }
        else {
            setChartHeight(210);
        }
    };

    useEffect(() => {
        updateChartHeight();
        window.addEventListener('resize', updateChartHeight);
        return () => {
            window.removeEventListener('resize', updateChartHeight);
        };
    }, []);

    useEffect(() => {
        if (Array.isArray(yourHoldingData) && yourHoldingData.length > 0) {
        const totalInvestment = yourHoldingData.reduce((sum, item) => sum + (item.marketValue || 0), 0);
    
        const formattedData = yourHoldingData.map((item) => {
            const percentage = totalInvestment > 0 ? ((item.marketValue / totalInvestment) * 100).toFixed(2) : 0;
            const name = item.description.split(" ")[0];
            return {
                name,
                percentage,
                color: getColor(name),
                marketValue: item.marketValue
            };            
        });
        setInvestmentData(formattedData);
        }
    }, [yourHoldingData]);

    const getColor = (name) => {
        const colorMapping = {
        "GC": "#317CFF",
        "SI": "#E04662",
        "ZC": "#F4F1ED",
        "AAPL": "#FF5733",
        "CL": "#40C796",
        "ZS": "#FBD914",
        };
        return colorMapping[name] || '#000000';
    };

    const treeMapOptions = {
        chart: {
            type: 'treemap',
            toolbar: {
                show: false,
            },
        },
        series: [
            {
                data: investmentData && investmentData.length > 0
                    ? investmentData.map((item) => ({
                        x: `${item.name} (${item.marketValue.toFixed(2) || 0})`, // Format the name and market value
                        y: item.percentage || 0, // Use the market value for the y-axis
                    }))
                    : [{ x: 'No Data Available', y: 1 }], // Default data for no investmentData
            },
        ],
        colors: investmentData.map(item => item.color),
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false,
                borderRadius: 0,
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#000'],
            },
        },
        stroke: {
            show: true,
            width: 3,
            colors: ['#1f1a27'],
        },
        tooltip: {
            enabled: true,
            style: {
                color: 'red',
                background: '#000'
            },
            theme: 'dark',
            formatter: function (val) {
                const point = val[0]?.dataPoint; // Check for valid dataPoint
                return point ? `${point.x}: ${point.y}` : 'No Data';
            },
        },
    };

    return (
        <Card className="main-card mt-3 card-large">
            <Card.Header className="pb-0">
                <div className="d-flex justify-content-between mt-1">
                    <h2 className="medium-heading mb-0">Account information</h2>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={handleSvgClick}
                            style={{ cursor: 'pointer' }}
                            >
                            <path d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="white" />
                            <path d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z" fill="white" />
                            <path d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z" fill="white" />
                        </svg>
                        <NavDropdown
                            show={showDropdown}
                            onToggle={handleToggle}
                            title=""
                            id="nav-dropdown"
                            align="end"
                            className="d-inline custom-nav-dropdown"
                            style={{ display: showDropdown ? 'block' : 'none' }}
                            >
                            <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('accountinformation')}>Close Window</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="pt-0 pr-2 pl-2">
                <CustomDropdown
                    options={['Demo Account', 'Personal Account', 'IRA Account']}
                    selectedOption={sortOption}
                    onSelect={handleSortChange}
                />
                <div className="table-responsive scrollbar-custom plr-2 w-t-div">
                    <Table hover className="watchlist-table">
                        <tbody>
                            <tr className="account-info">
                                <th className="gray-text">Balance</th>
                                <th className="gray-text">Invested</th>
                                <th className="text-end gray-text">Today’s P/L</th>
                                <th className="text-end gray-text">Today’s P/L%</th>
                            </tr>
                            <tr>
                                <td>{balance.toFixed(2)}</td>
                                <td>{accruedCashAmount.toFixed(2)}</td>
                                <td className={`text-end align-middle ${dailyPnl > 0 ? 'text-success' : 'text-danger'}`}>{dailyPnl.toFixed(2)}</td>
                                <td className={`text-end align-middle ${todayPnlPer > 0 ? 'text-success' : 'text-danger'}`}>{todayPnlPer}%</td>
                            </tr>
                            <tr>
                                <th className="gray-text">Available $</th>
                                <th className="gray-text">w/d</th>
                                <th className="text-end gray-text">QA P/L</th>
                                <th className="text-end gray-text">QA P/L %</th>
                            </tr>
                            <tr>
                                <td>{availableFunds.toFixed(2)}</td>
                                <td>-</td>
                                <td className="text-success text-end align-middle">-</td>
                                <td className="text-success text-end align-middle">-</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <hr className="mt-3 mb-3"/>
                <div style={{paddingLeft: "5px", paddingRight: "5px"}}>
                    <span className="gray-text">Risk level by AI</span>

                    <span className={`label ${aioperation < 2.88 ? 'safe' : ''}`}>{aioperation < 2.88 ? 'Safe' : 'Dangerous'}</span>

                    <ProgressBar percentage={aioperation} />

                    <div className="blue-div mt-3">
                        Your investment has been safe so far, with steady asset growth. Learn more about how to further increase your assets 
                    </div>
                </div>
                <hr />
                <div className="treemap-section" style={{paddingLeft: "5px", paddingRight: "5px"}}>
                    <span className="gray-text">Allocation - Value</span>
                    <ApexCharts options={treeMapOptions} series={treeMapOptions.series} type="treemap"  height={chartHeight} style={{ marginTop: "-15px" }} />
                </div>
            </Card.Body>
        </Card>
    );
}

export default AccountInformation;
