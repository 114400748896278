import React, { useState, useEffect, useContext } from 'react';
import { Card, Table, NavDropdown } from 'react-bootstrap';
import ProgressBarRiskLevel from './ProgressBarRiskLevel';
import ProgressBarConservative from './ProgressBarConservative';
import Chart from 'react-apexcharts';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InvestmentDot from './InvestmentDot';
import { AuthContext } from '../context/AuthContext';

function PerformanceOverview() {
    const { currentDate, currentTime, accountInfoData, yourHoldingData, aioperation, availableBalance, userData, aiPl} = useContext(AuthContext);
    const [riskLevel1] = useState(40);
    const [totalRealizedPnl, setTotalRealizedPnl] = useState();
    const [avgPnlPercentage, setAvgPnlPercentage] = useState();
    const [showDropdown, setShowDropdown] = useState(false);
    const [aiInvestedAmout, setAiInvestedAmout] = useState("");
    const [orders, setOrderData] = useState([]);
    const accruedCashAmount = accountInfoData?.summary?.netLiquidationValue|| 0;
    const availableFunds = accountInfoData?.summary?.availableFunds || 0;

    const handleToggle = (isOpen) => {
        setShowDropdown(isOpen);
    };

    const handleSvgClick = () => {
        setShowDropdown(!showDropdown);
    };

    const [investmentData, setInvestmentData] = useState([]);

    useEffect(() => {
        // Set the orders from userData
        setOrderData(userData?.orders || []);
    }, [userData]);

    useEffect(() => {
        if (orders.length > 0) {
            // Group orders by symbol
            const groupedOrders = orders.reduce((acc, order) => {
                if (!acc[order.symbol]) {
                    acc[order.symbol] = [];
                }
                acc[order.symbol].push(order);
                return acc;
            }, {});

            console.log(groupedOrders);
            
    
            // Calculate Buy - Sell for each symbol
            const result = Object.entries(groupedOrders).map(([symbol, orders]) => {
                let totalBuy = 0;
                let totalSell = 0;
        
                orders.forEach((order) => {
                    const value = order.amount * order.price;
                    if (order.order_type === "Buy") {
                        totalBuy += value;
                    } else if (order.order_type === "Sell") {
                        totalSell += value;
                    }
                });
        
                return {
                    symbol,
                    totalBuy,
                    totalSell,
                    net: totalBuy - totalSell,
                    color: getColor(symbol),
                };
            });
            // Calculate percentages
            const totalNet = result.reduce((sum, item) => sum + Math.abs(item.net), 0); // Use absolute value for percentage calculation
            const resultWithPercentages = result.map(item => ({
                ...item,
                percentage: ((Math.abs(item.net) / totalNet) * 100).toFixed(2), // Round to 2 decimal places
            }));

            setInvestmentData(resultWithPercentages);
            console.log("Calculation Result:", result);
        }
    }, [orders]);

    const getColor = (name) => {
        const colorMapping = {
        "GC": "#317CFF",
        "SI": "#E04662",
        "ZC": "#F4F1ED",
        "AAPL": "#FF5733",
        "CL": "#40C796",
        "ZS": "#FBD914",
        };
        return colorMapping[name] || '#000000'; // Default color if no match
    };

    useEffect(() => {
        if (Array.isArray(yourHoldingData) && yourHoldingData.length > 0) {
            const totalInvestment = yourHoldingData.reduce((sum, item) => sum + (item.marketValue || 0), 0);
      
            // Calculate total unrealized P/L
            const totalRealizPnl = yourHoldingData.reduce((sum, item) => sum + (item.realizedPnl || 0), 0);
            
            setTotalRealizedPnl(totalRealizPnl.toFixed(2) || 0);

            // Format investment data
            const formattedData = yourHoldingData.map((item) => {
                const percentage = totalInvestment > 0 ? ((item.marketValue / totalInvestment) * 100).toFixed(2) : 0;
                const name = item.description.split(" ")[0];
                return {
                name,
                percentage,
                color: getColor(name),
                };
            });

            let averagePnlPercentage;

            if (accruedCashAmount === 0) {
                averagePnlPercentage = 0; // Set to 0 if accruedCashAmount is 0 to avoid division by zero
            } else {
                averagePnlPercentage = ((totalRealizPnl / accruedCashAmount) * 100);
                
                if (isNaN(averagePnlPercentage) || !isFinite(averagePnlPercentage)) {
                averagePnlPercentage = 0; // Set to 0 if result is NaN or Infinity
                } else {
                averagePnlPercentage = averagePnlPercentage.toFixed(2); // Format the result to 2 decimal places
                }
            }

            // Set the average P/L percentage
            setAvgPnlPercentage(averagePnlPercentage);

            // setInvestmentData(formattedData);
        }
    }, [yourHoldingData, accruedCashAmount]);

    const [sort, setSort] = React.useState('category');

    const handleChange = (event) => {
        const selectedSort = event.target.value;
        setSort(selectedSort);
    
        // Sorting logic
        const sortedData = [...investmentData]; // Create a copy before sorting
        if (selectedSort === 'category') {
        sortedData.sort((a, b) => a.symbol.localeCompare(b.symbol));
        } else if (selectedSort === 'amount' || selectedSort === 'performance') {
        sortedData.sort((a, b) => b.percentage - a.percentage);
        }
    
        // Update state to trigger re-render
        setInvestmentData(sortedData);
    };

    const chartOptions = {
        series: investmentData.map(item => parseFloat(item.percentage)),
        options: {
        chart: {
            type: 'donut',
            height: '100%', // Make the chart responsive to the container height
        },
        labels: investmentData.map(item => item.symbol),
        colors: investmentData.map(item => item.color),
        stroke: {
            show: true,
            width: 0,
            colors: ['transparent'],
        },
        legend: {
            show: false,  // Hide the legend
        },
        dataLabels: {
            enabled: false,
        },
        },
    };

    useEffect(() => {
        if (userData) {
          setAiInvestedAmout(userData.aiAmountUsed || aiInvestedAmout);
        }
      }, [userData, aiInvestedAmout]);      

    return (
        <Card className="main-card mt-3 card-large">
            <Card.Header className="pb-0">
                <div className="d-flex justify-content-between mt-1">
                    <div className="d-flex align-items-center">
                        <h2 className="medium-heading mb-0">Performance Overview</h2>
                        <div className='ai_block'>
                            <span className="ml-2">AI</span>
                        </div>
                    </div>
                    
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={handleSvgClick}
                            style={{ cursor: 'pointer' }}
                            >
                            <path d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="white" />
                            <path d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z" fill="white" />
                            <path d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z" fill="white" />
                        </svg>
                        <NavDropdown
                            show={showDropdown}
                            onToggle={handleToggle}
                            title=""
                            id="nav-dropdown"
                            align="end"
                            className="d-inline custom-nav-dropdown"
                            style={{ display: showDropdown ? 'block' : 'none' }}
                            >
                            {/* <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('performance')}>Close Window</NavDropdown.Item> */}
                        </NavDropdown>
                    </div>
                </div>
                <div className="small-gray small-gary-date">{currentDate} | {currentTime}</div>
            </Card.Header>
            <Card.Body className="pt-0 pr-2 pl-2">
                <div className="table-responsive scrollbar-custom plr-2 w-t-div">
                    <Table hover className="watchlist-table">
                        <tbody>
                            <tr className="account-info">
                                <th className="gray-text">Total Balance</th>
                                <th className="gray-text">Total Invested</th>
                                <th className="text-end gray-text">P/L</th>
                                <th className="text-end gray-text">P/L%</th>
                            </tr>
                            <tr>
                                <td>{availableFunds.toFixed(2)}</td>
                                <td>{accruedCashAmount.toFixed(2)}</td>
                                <td className={`text-end align-middle ${totalRealizedPnl > 0 ? 'text-success' : 'text-danger'}`}>{totalRealizedPnl || 'N/A'}</td>
                                <td className={`text-end align-middle ${avgPnlPercentage > 0 ? 'text-success' : 'text-danger'}`}>
                                    {avgPnlPercentage !== null && avgPnlPercentage !== undefined ? `${avgPnlPercentage}%` : 'N/A'}
                                </td>

                            </tr>
                            <tr>
                                <th className="gray-text">AI Balance</th>
                                <th className="gray-text">AI Invested</th>
                                <th className="text-end gray-text">AI P/L</th>
                                <th className="text-end gray-text">AI P/L %</th>
                            </tr>
                            <tr>
                                <td>{availableBalance !== undefined && typeof availableBalance === 'number'
                                        ? availableBalance.toFixed(2)
                                        : '0.00'}</td>
                                <td>{aiInvestedAmout !== undefined && typeof aiInvestedAmout === 'number'
                                        ? aiInvestedAmout.toFixed(2)
                                        : '0.00'}</td>
                                <td className={`text-end align-middle ${aiPl?.today_pl > 0 ? 'text-success' : 'text-danger'}`}>{aiPl?.today_pl?.toFixed(2) || "N/A"}</td>
                                <td className={`text-end align-middle ${aiPl?.today_pl_percent > 0 ? 'text-success' : 'text-danger'}`}>{aiPl?.today_pl_percent?.toFixed(2) || "N/A"}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <hr className="mt-2 mb-2" />
                <div className="gray-text mt-1" style={{marginLeft: '10px'}}>AI Investment</div>
                <div className="row mt-1 justify-content-between">
                <div className="col-4 p-0" style={{ marginLeft: '-35px' }}>
                    <Chart
                        options={
                            chartOptions.series && chartOptions.series.length > 0
                                ? chartOptions.options
                                : {
                                    ...chartOptions.options,
                                    labels: ["No Data"], // Default label
                                }
                        }
                        series={
                            chartOptions.series && chartOptions.series.length > 0
                                ? chartOptions.series
                                : [100] // Default value for "No Data"
                        }
                        type="donut"
                        width="139%"
                    />
                </div>
                <div className="col-8">
                    <FormControl className="custom-form-control m-0" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        value={sort}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        className="custom-select"
                    >
                        <MenuItem value="category"> Sort by category </MenuItem>
                        <MenuItem value="amount"> Sort by amount </MenuItem>
                        <MenuItem value="performance"> Sort by performance </MenuItem>
                    </Select>
                    </FormControl>
                    
                    <table className="w-100 mt-2">
                        <tbody>
                            {investmentData.length > 0 ? (
                                investmentData.map((investment, index) => {
                                // Create rows for two investments
                                if (index % 2 === 0) {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <InvestmentDot color={investment.color} />
                                                <span className="gray-text">{investment.symbol}</span>
                                            </td>
                                            <td>
                                                <span className="bold">{investment.percentage}%</span>
                                            </td>
                                            {/* Check if there is a next investment to display in the same row */}
                                            {investmentData[index + 1] ? (
                                            <>
                                                <td>
                                                    <InvestmentDot color={investmentData[index + 1].color} />
                                                    <span className="gray-text">{investmentData[index + 1].symbol}</span>
                                                </td>
                                                <td>
                                                    <span className="bold">{investmentData[index + 1].percentage}%</span>
                                                </td>
                                            </>
                                            ) : (
                                            // If there's no next investment, fill empty cells
                                            <>
                                                <td></td>
                                                <td></td>
                                            </>
                                            )}
                                        </tr>
                                    );
                                }
                                return null; // For odd indices, we don't need to render anything
                            })
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="no-data">No Data Available</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    
                </div>
                </div>
                <hr className="mt-1 mb-2" />
                <div style={{paddingLeft: "5px", paddingRight: "5px"}}>
                    <span className="gray-text">Risk level by AI</span>

                    <span className={`label ${aioperation < 50 ? 'safe' : ''}`}>{aioperation < 50 ? 'Safe' : 'Dangerous'}</span>

                    <ProgressBarRiskLevel percentage={aioperation} />

                    <span className="gray-text">Profit ratio by AI</span>

                    <span className={`label-blue ${riskLevel1 < 50 ? 'conservative' : ''}`}>{riskLevel1 < 50 ? 'Conservative' : 'Progressive'}</span>

                    <ProgressBarConservative percentage={riskLevel1} />

                    <div className="blue-div mt-1">
                    Your investment has been safe so far. Currently, the settings are conservative. Adjusting the AI's strategy or allocating more funds has a 76% chance of increasing returns by 15%.
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default PerformanceOverview;
