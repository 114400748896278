// ProgressBar.js
import React from 'react';

const ProgressBarConservative = ({ percentage }) => {
  return (
    <div className="">
     <div className="progress-bar-container-ai progress-bar2 mt-1">
          <div
            className={`filler ${percentage <= 50 ? 'blue' : ''}`}
            style={{ width: `${percentage}%` }}
          >
            {/* Add the cross line */}
            <div className="cross-line"></div>
          </div>
        </div>
      <div className="progress-container-portfolio">
        <span className="small-gray">Conservative</span>
        <span className="small-gray right">Progressive</span>
      </div>
    </div>
  );
};

export default ProgressBarConservative;
