import { React, useContext, useState, useEffect, useCallback} from 'react'
import { Card } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContext';
import Chart from "react-apexcharts";
import CustomDropdown from './CustomDropdown';
import { use } from 'react';

const Estimate = () => {

    const { latestWasdeData, getWasde_data, getCropCondition, getCropProgress, bid_ask_data, setTradingviewSymbol, tradingviewsymbol,trading_live_data, fetchSymbolDetails, setCurrentPrice, sethighPrice, setlowPrice } = useContext(AuthContext); // Use the processed latestWasdelatestWasdeData
    const [year, setYear] = useState(new Date().getFullYear()); // Default to the current year
    const [years, setYears] = useState([]);
    const [crop, setCrop] = useState('Corn');
    const [cropcondition, setCropCondition] = useState('Corn Condition');
    const [cropstage, setCropStage] = useState('Corn Planted'); 
    const [country, setCountry] = useState('United States');
    const [supplyAndUseChartOptions, setSupplyAndUseChartOptions] = useState({});
    const [supplyAndUseChartSeries, setSupplyAndUseChartSeries] = useState([]);
    const [yieldChartOptions, setYieldChartOptions] = useState([]);
    const [yieldChartSeries, setYieldChartSeries] = useState([]);
    const [acresChartOptions, setAcresChartOptions] = useState([]);
    const [acresChartSeries, setAcresChartSeries] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [chartHeight, setChartHeight] = useState(210); 

    useEffect(() => {
      const handleResize = () => {
        const height = window.innerHeight;
          if (height <= 800) {
            setChartHeight(150);
        } 
        else if (height <= 880) {
            setChartHeight(185);
        } 
        else if(height>= 900){
            setChartHeight(215);
        }
        else {
            setChartHeight(240); // Default height or adjust for other conditions
        }
      };
  
      handleResize(); // Set initial height
      window.addEventListener('resize', handleResize);
  
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);
  
    const handleToggle = () => {
      setIsOpen((prev) => !prev);
    };    

    // Initialize the years dropdown from 2011 to current year + 1
    useEffect(() => {
      const currentYear = new Date().getFullYear();
      const yearRange = [];
      for (let i = 2011; i <= currentYear + 1; i++) {
        yearRange.push(i);
      }
      setYears(yearRange.reverse()); // Reverse to show latest year first
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if (year && crop) {
            // Fetch WASDE data
            await getWasde_data(year, crop.toLowerCase());

            await getCropCondition(year, cropcondition);

            await getCropProgress(year, cropstage);
    
            // Determine the symbol based on the selected crop
            const symbol = crop === 'Corn' ? 'ZC.C.0' : 'ZS.C.0';
            await bid_ask_data(symbol);
    
            // Determine the TradingView symbol based on the selected crop
            const selectedSymbol = crop === 'Corn' ? 'ZC1!' : 'ZS1!';
            setTradingviewSymbol(selectedSymbol); // Update symbol state
    
            const priceSymbol = crop === 'Corn' ? 'ZC' : 'ZS';
            // await getLiveMarketData(priceSymbol);
    
            // Fetch symbol details
            const symbolData = await fetchSymbolDetails(priceSymbol);
    
            // Process the data to extract monthsList
            const monthsList = symbolData?.flatMap(item =>
              item.sections
                .filter(section => section.secType === "FUT" && section.months)
                .map(section => section.months.split(';'))
            )
              .flat()
              .filter((value, index, self) => self.indexOf(value) === index);
    
            console.log(monthsList);
    
            // Call trading_live_data with the first item of monthsList
            if (monthsList && monthsList.length > 0) {
              const tradingDataSymbol = `${priceSymbol}-${monthsList[0]}`;
              const response = await trading_live_data(tradingDataSymbol);
              setCurrentPrice(response.data?.[0]?.close/ 1000000000);
              sethighPrice(response.data?.[0]?.high/ 1000000000);
              setlowPrice(response.data?.[0]?.low/ 1000000000);
            }
          }
        } catch (error) {
          console.error("Error during data fetching:", error);
        }
      };
    
      fetchData();
    }, [year, crop, cropcondition, getWasde_data]);
    
  

    useEffect(() => {
      console.log('Symbol for tradingview:', tradingviewsymbol );

    },[tradingviewsymbol]);

    const handleYearChange = (event) => {
      setYear(event);
      setIsOpen(false);
    };

    const handleCropChange = (event) => {
      const selectedCrop = event;
      setCrop(selectedCrop);
      setCropCondition(selectedCrop + ' Condition');
      setCropStage(selectedCrop + 'Planted');
    }

    useEffect(() => {
      console.log('Crp condition:', cropcondition)
    },[cropcondition]);

    const handleCountryChange = (event) => {
      const selectedCountry = event;
      setCountry(selectedCountry);
    };

    const prepareSupplyAndUseChart = useCallback((latestWasdeData) => {
      if (!latestWasdeData) return;
      // Calculate the dynamic years array based on the selected year
      const currentYear = parseInt(year); // Ensure the year is an integer
      const years = [
        `${(currentYear - 3) % 100}/${(currentYear - 2) % 100}`,
        `${(currentYear - 2) % 100}/${(currentYear - 1) % 100}`,
        `${(currentYear - 1) % 100}/${currentYear % 100}`,
        `${currentYear % 100}/${(currentYear + 1) % 100}`,
        `${(currentYear + 1) % 100}/${(currentYear + 2) % 100}`,
      ];

      // Merge production and use data into one line
      const production = [
        latestWasdeData.ly3_production,
        latestWasdeData.ly2_production,
        latestWasdeData.ly_production,
        latestWasdeData.cy_production,
        latestWasdeData.fy_production,  // Continue the line
      ];

      const use = [
        latestWasdeData.ly3_use,
        latestWasdeData.ly2_use,
        latestWasdeData.ly_use,
        latestWasdeData.cy_use,
        latestWasdeData.fy_use,
      ];
  
      setSupplyAndUseChartOptions({
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false, // Disable animations
          },
        },
        colors: ['#40C796', '#E04662'],
        xaxis: {
          categories: years,
          labels: {
            style: {
              colors: "#A8A8A8", // Optional: Customize the label color
              fontSize: "14px", 
            },
          },
          axisBorder: {
            show: true, // Ensure the axis line is visible
            color: "#646464", // Set the custom color for the X-axis line
            height: 2, // Optional: Adjust the thickness of the line
            offsetX: 0, // Optional: Horizontal offset for the line
            offsetY: 0, // Optional: Vertical offset for the line
          },
          axisTicks: {
            show: false,
          }
        },
        yaxis: {
          opposite: true,
          labels: {
            style: {
              colors: "#A8A8A8", // Optional: Customize the label color
              fontSize: "14px"
            },
          },
        },
        title: {
          text: "Supply/Demand (Million Bushels)", // Chart title text
          align: "left", // Align the title to the center
          style: {
            fontSize: "12px", // Font size of the title
            fontWeight: "bold", // Font weight of the title
            color: "#FFF", // Font color of the title
          },
        },
        stroke: {
          curve: "straight",
          width: 1, 
        },
        tooltip: {
          enabled: false
        },
        legend: {
          position: "left", // Position the legend on the left
          fontSize: "12px", // Font size of the legend labels
          fontWeight: 400, // Font weight of the legend labels
          labels: {
            colors: "#fff", // Text color of the legend labels
            useSeriesColors: false, // Use series colors for labels (set to true if preferred)
          },
          offsetX: -10,
          markers: {
            width: 10, // Width of the marker
            height: 10,
            shape: "square", // Height of the marker
            fillColors: ['#40C796', '#E04662'], 
            offsetX: -10,
          },
          itemMargin: {
            vertical: 5, // Vertical margin between legend items
            horizontal: 0, // Horizontal margin between legend items
          },
          onItemClick: {
            togglelatestWasdeDataSeries: false
          },
          onItemHover: {
            highlightlatestWasdeDataSeries: false
          },
          // customLegendItems: ["Supply, total", "Use, total"],
        },
        grid: {
          show: false, // Turn off the grid
          padding: {
            left: 87, // Add left padding to shift the graph to the right
            right: 30,
            top: -30,
            bottom: -10,
          },
        },
      });
  
      setSupplyAndUseChartSeries([
        {
          name: "Supply, total",
          data: production,
        },
        {
          name: "Use, total",
          data: use,
        },
      ]);
    }, [year]);

    const prepareYieldChart = useCallback((latestWasdeData) => {
      if (!latestWasdeData) return;
      // Yield latestWasdeData for a single line chart
      const yieldSolid = [
        latestWasdeData.ly3_yield,
        latestWasdeData.ly2_yield,
        latestWasdeData.ly_yield,
        latestWasdeData.cy_yield,
        null
      ];

      const yieldDotted = [
        null,
        null,
        null,
        latestWasdeData.cy_yield,
        latestWasdeData.fy_yield,
      ]
    
      setYieldChartOptions({
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false, // Disable animations
          },
        },
        colors: ['#FBD914'], // Custom color for the yield line
        xaxis: {
          labels: {
            show: false, // Hide only the X-axis labels
          },
          axisBorder: {
            show: false, // Keep the X-axis line visible
          },
          axisTicks: {
            show: false, // Keep the ticks visible
          },
        },
        yaxis: {
          opposite: true,
          labels: {
            style: {
              colors: "#A8A8A8", // Customize label color
              fontSize: "14px",
            },
          },
        },
        title: {
          text: "Yield (Bushels per Acre)", // Chart title for yield
          align: "left",
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            color: "#FFF",
          },
        },
        stroke: {
          curve: "straight",
          width: 1, 
          dashArray: [0, 5]
        },
        tooltip: {
          enabled: false, // Disable tooltips
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "left", // Position the legend on the left
          fontSize: "12px", // Font size of the legend labels
          fontWeight: 400, // Font weight of the legend labels
          labels: {
            colors: "#fff", // Text color of the legend labels
            useSeriesColors: false, // Use series colors for labels (set to true if preferred)
          },
          offsetX: -10,
          markers: {
            width: 10, // Width of the marker
            height: 10,
            shape: "square", // Height of the marker
            fillColors: ['#FBD914'], 
            offsetX: -10,
          },
          itemMargin: {
            vertical: 5, // Vertical margin between legend items
            horizontal: 0, // Horizontal margin between legend items
          },
          onItemClick: {
            togglelatestWasdeDataSeries: false
          },
          onItemHover: {
            highlightlatestWasdeDataSeries: false
          },
          customLegendItems: ["Yield"],
        },
        grid: {
          show: false,
          padding: {
            left: 125,
            right: 35,
            top: -30,
            bottom: 0,
          },
        },
      });
    
      setYieldChartSeries([
        { name: "Yield (Solid)", data: yieldSolid },
        { name: "Yield (Dotted)", data: yieldDotted },
      ]);
    }, []);
    
    const prepareAcresChart = useCallback((latestWasdeData) => {
      if (!latestWasdeData) return;
       // Split acres planted data for solid and dotted lines
      const plantedSolid = [
        latestWasdeData.ly3_ac_planted,
        latestWasdeData.ly2_ac_planted,
        latestWasdeData.ly_ac_planted,
        latestWasdeData.cy_ac_planted,
        null, // End solid line before future year
      ];

      const plantedDotted = [
        null, // Start dotted line after the current year
        null,
        null,
        latestWasdeData.cy_ac_planted,
        latestWasdeData.fy_ac_planted,
      ];

      // Split acres harvested data for solid and dotted lines
      const harvestedSolid = [
        latestWasdeData.ly3_ac_harvested,
        latestWasdeData.ly2_ac_harvested,
        latestWasdeData.ly_ac_harvested,
        latestWasdeData.cy_ac_harvested,
        null, // End solid line before future year
      ];

      const harvestedDotted = [
        null, // Start dotted line after the current year
        null,
        null,
        latestWasdeData.cy_ac_harvested,
        latestWasdeData.fy_ac_harvested,
      ];
    
      setAcresChartOptions({
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false, // Disable animations
          },
        },
        colors: ['#40C796', '#40C796', '#317CFF', '#317CFF'], // Custom colors for the two lines
        xaxis: {
          labels: {
            show: false, // Hide only the X-axis labels
          },
          axisBorder: {
            show: false, // Keep the X-axis line visible
          },
          axisTicks: {
            show: false, // Keep the ticks visible
          },
        },
        yaxis: {
          opposite: true,
          labels: {
            style: {
              colors: "#A8A8A8", // Optional: Customize the label color
              fontSize: "14px",
            },
          },
        },
        title: {
          text: "Acres Planted vs Harvested", // Chart title text
          align: "left", // Align the title to the center
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            color: "#FFF",
          },
        },
        stroke: {
          curve: "straight",
          width: 1, // Slightly thicker lines for better visibility
          dashArray: [0,5,0,5]
        },
        tooltip: {
          enabled: false, // Enable tooltips for better latestWasdeData understanding
        },
        legend: {
          position: "left", // Position the legend on the left
          fontSize: "12px", // Font size of the legend labels
          fontWeight: 400, // Font weight of the legend labels
          labels: {
            colors: "#fff", // Text color of the legend labels
            useSeriesColors: false, // Use series colors for labels (set to true if preferred)
          },
          offsetX: -10,
          markers: {
            width: 10, // Width of the marker
            height: 10,
            shape: "square", // Height of the marker
            fillColors: ['#40C796', '#317CFF'], 
            offsetX: -10,
          },
          itemMargin: {
            vertical: 5, // Vertical margin between legend items
            horizontal: 0, // Horizontal margin between legend items
          },
          onItemClick: {
            togglelatestWasdeDataSeries: false
          },
          onItemHover: {
            highlightlatestWasdeDataSeries: false
          },
          customLegendItems: ["Acres Planted", "Acres Harvested"],
        },
        grid: {
          show: false, // Turn off the grid
          padding: {
            left: 60, // Add left padding to shift the graph to the right
            right: 45,
            top: -30,
            bottom: 0,
          },
        },
      });
    
      setAcresChartSeries([
        { name: "Acres Planted (Solid)", data: plantedSolid },
        { name: "Acres Planted (Dotted)", data: plantedDotted },
        { name: "Acres Harvested (Solid)", data: harvestedSolid },
        { name: "Acres Harvested (Dotted)", data: harvestedDotted },
      ]);
    }, []);

    useEffect(() => {
      if (latestWasdeData) {
        console.log('Last item:', latestWasdeData);
        prepareSupplyAndUseChart(latestWasdeData);
        prepareYieldChart(latestWasdeData);
        prepareAcresChart(latestWasdeData);
      }
    }, [latestWasdeData, prepareSupplyAndUseChart, prepareYieldChart, prepareAcresChart]);

    return (
        <>
            <Card className='estimate-card home-chart mt-3'>
                <Card.Header>
                        <div className="d-flex justify-content-between mt-1">
                          <div class="ai-button-container">
                            <h2 className="medium-heading mb-0">World Agricultural Supply and Demand Estimate</h2>
                            <img src='/assets/dropdown.svg' alt='dropdown-icon'/>
                          </div>
                        </div>
                </Card.Header>
                <Card.Body className="pt-2">
                    <div className='flex-layout'>
                      <div className="custom-dropdown" style={{width: "80px"}}>
                        <button className="select-btn" onClick={handleToggle}>
                            {year} <img src={`${process.env.PUBLIC_URL}/images/down 2.svg`} alt="icon" className="float-right mt-1" />
                        </button>
                        {isOpen && (
                          <div className="select-options">
                            {years.map((option) => (
                              <div
                                key={option}
                                className="option"
                                onClick={() => handleYearChange(option)}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <CustomDropdown
                        options={['United States']}
                        selectedOption={country}
                        onSelect={handleCountryChange}
                        width="138px"
                      />  
                      <div className='ml-2'></div>
                      <CustomDropdown
                        options={['Corn', 'Soybean']}
                        selectedOption={crop}
                        onSelect={handleCropChange}
                        width="105px"
                      />  
                    </div>
                    <div className="chart-container">
                      <div className="dotted-line">
                        {/* This dotted background remains independent */}
                      </div>
                        {latestWasdeData ? (
                          <div className="chart-wrapper">
                            {acresChartOptions && acresChartSeries.length > 0 && (
                              <>
                                <Chart
                                  options={acresChartOptions}
                                  series={acresChartSeries}
                                  type="line"
                                  height={chartHeight}
                                />
                                <hr className='estimate-divider'/> {/* Divider after the first chart */}
                              </>
                            )}
                            {yieldChartOptions && yieldChartSeries.length > 0 && (
                              <>
                                <Chart
                                  options={yieldChartOptions}
                                  series={yieldChartSeries}
                                  type="line"
                                  height={chartHeight}
                                />
                                <hr className='estimate-divider'/> {/* Divider after the second chart */}
                              </>
                            )}
                            {supplyAndUseChartOptions && supplyAndUseChartSeries.length > 0 && (
                              <Chart
                                options={supplyAndUseChartOptions}
                                series={supplyAndUseChartSeries}
                                type="line"
                                height={chartHeight}
                              />
                            )}
                          </div>
                        ) : (
                          <p>Loading chart...</p>
                        )}
                    </div>


                </Card.Body>
            </Card>
        </>
    )
}

export default Estimate;