import React, { useState, useContext, useEffect } from 'react';
import { Card, NavDropdown, ButtonGroup, Button, Form, Dropdown  } from 'react-bootstrap';
import CustomDropdown from './CustomDropdown';
import Chart from 'react-apexcharts';
import Popover from '@mui/material/Popover';
import { useWidgetContext } from '../context/DragContext';
import { AuthContext } from '../context/AuthContext';

const AiPerformance = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { removeWidgetFromGrid } = useWidgetContext();
    const [activeButton, setActiveButton] = useState('1D');
    const { getAiPnlResults } = useContext(AuthContext);
    const [chartData, setChartData] = useState({ categories: [], series: [] });
    const [aiPnlResults, setAiPnlResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [chartType, setChartType] = useState('line'); // 'line' or 'bar'
    const [chartOption, setChartOption] = useState('Line Chart');
    const [selectedSeries, setSelectedSeries] = useState([true, true, true, false, false]); // Toggle state for each series
    const [selectedDropdownOption, setSelectedDropdownOption] = useState('1M – 11M');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        const fetchPnlResults = async () => {
            const response = await getAiPnlResults(activeButton);
            setAiPnlResults(response);
        };
    
        fetchPnlResults();
    }, [activeButton]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleButtonClick = (buttonLabel) => {
        setActiveButton(buttonLabel);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleToggle = (isOpen) => {
        setShowDropdown(isOpen);
    };

    const handleSvgClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleDropdownSelect = (eventKey) => {
        setSelectedDropdownOption(eventKey); 
        setActiveButton(eventKey); // Set active state to the selected value
        console.log(eventKey);
    };

    const handleChartChange = (option) => {
        setChartOption(option);
        setChartType(option === 'Line Chart' ? 'line' : 'bar');
    };

    const handleSeriesToggle = (index) => {
        const updatedSelectedSeries = [...selectedSeries];
        updatedSelectedSeries[index] = !updatedSelectedSeries[index];
        setSelectedSeries(updatedSelectedSeries);
    };

    const getRandomColor = (name) => {
        const colorMapping = {
        "GC": "#008ffb",
        "SI": "#ff4560",
        "ZC": "#F4F1ED",
        "AAPL": "#FF5733",
        "Core" : "#8A2BE2",
        "CL": "#775dd0",
        "ZS": "#feb019",
        };
        return colorMapping[name] || '#000000'; // Default color if no match
    };

    // Chart data and options for ApexCharts
    const chartOptions = {
        chart: {
            type: chartType,
            height: 350,
            toolbar: { show: false }
        },
        xaxis: {
            categories: chartData.categories,
            labels: { style: { colors: '#A8A8A8' } },
        },
        yaxis: {
            opposite: true,
            labels: {
                style: { colors: '#A8A8A8' },
                formatter: function (value) {
                    return value.toFixed(2) + '%';
                }
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        grid: {
            borderColor: '#555',
        },
        legend: {
            // position: 'left',
            // labels: { colors: '#fff' }
            show: false,
        },
        tooltip: {
            theme: 'dark'
        }
    };

    const pnlResults = aiPnlResults;

    // Parse and transform data for the chart
    useEffect(() => {
        const results = pnlResults?.pnl_results || {};
        // Extract unique timestamps and sort them, ignoring 'Core'
        const timestamps = [
            ...new Set(
                Object.keys(results)
                    .filter(symbol => symbol !== 'Core') // Exclude 'Core'
                    .flatMap(symbol => results[symbol].map(data => data.timestamp))
            )
        ].sort();
    
        // Map data to series format
        const seriesData = Object.keys(results)
            .filter(symbol => symbol !== 'Core') // Exclude 'Core'
            .map(symbol => {
                const dataPoints = timestamps.map(ts => {
                    const entry = results[symbol].find(data => data.timestamp === ts);
                    return entry ? parseFloat(entry.pnl) : 0; // Default to 0 if no data for the timestamp
                });
    
                return {
                    name: symbol,  // Use the symbol as the series name
                    data: dataPoints,
                    color: getRandomColor(symbol) // Assign color based on the symbol
                };
            });
    
        setChartData({
            categories: timestamps,
            series: seriesData
        });
    
        // Initialize all series toggles to true
        setSelectedSeries(new Array(seriesData.length).fill(true));
    }, [pnlResults]);

    const filteredSeries = chartData.series.filter((_, index) => selectedSeries[index]);

    return (
        <Card className="main-card mt-3 card-large">
            <Card.Header className="pb-0">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <h2 className="medium-heading mb-0">Ranking of Positions</h2>
                        <div className='ai_block'>
                            <span className="ml-2">AI</span>
                        </div>
                    </div>

                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={handleSvgClick}
                            style={{ cursor: 'pointer' }}
                        >
                            <path d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="white" />
                            <path d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z" fill="white" />
                            <path d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z" fill="white" />
                        </svg>
                        <NavDropdown
                            show={showDropdown}
                            onToggle={handleToggle}
                            title=""
                            id="nav-dropdown"
                            align="end"
                            className="d-inline custom-nav-dropdown"
                            style={{ display: showDropdown ? 'block' : 'none' }}
                        >
                            <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('performance')}>Close Window</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
                <ButtonGroup className="chart-buttons" style={{marginLeft: "6px"}}>
                    {['1D', '5D', '1M', '6M', 'YTD', '5Y', '10Y'].map((label) => (
                        <Button
                            key={label}
                            className={activeButton === label ? 'active ml-0' : ' ml-0'}
                            onClick={() => handleButtonClick(label)}
                        >
                            {label}
                        </Button>
                    ))}
                    {/* Dropdown button for additional ranges */}
                    <Dropdown as={ButtonGroup} onSelect={handleDropdownSelect}>
                        <Dropdown.Toggle
                            variant="secondary" // Adjust variant to match the design
                            className={activeButton === selectedDropdownOption ? 'active ml-0' : ' ml-0'} // Highlight if a range is selected
                        >
                            {selectedDropdownOption}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='preformance-dropdown'>
                            {['1M – 11M', '1Y – 10Y'].map((option, index) => (
                                <Dropdown.Item key={index} eventKey={option}>
                                    {option}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
            </Card.Header>
            <Card.Body className="pt-0">
                <div className="d-flex mb-3">

                    <div>
                        <Button aria-describedby={id} variant="contained" className="dropdown-btn" onClick={handleClick}>
                        <span className="holdings">Holdings</span> <img src={`${process.env.PUBLIC_URL}/images/down 2.svg`} alt="icon" className="float-right mt-1" style={{marginLeft: "5px"}} />
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            style={{marginTop: "5px"}}
                        >
                            <Form className="p-2">
                                {Object.keys(pnlResults?.pnl_results || {})
                                .filter(symbol => symbol !== 'Core')
                                .map((symbol, index) => (
                                    <div key={index} className="d-flex justify-content-between mb-2">
                                        <span className="ml-2">{symbol}</span>
                                        <Form.Check
                                            type="switch"
                                            id={`toggle-${symbol.toLowerCase()}`}
                                            checked={selectedSeries[index]} // Ensure index matches series array
                                            onChange={() => handleSeriesToggle(index)}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </Popover>
                    </div>

                    <CustomDropdown
                        options={['Line Chart', 'Bar Chart']}  // The options you want to display
                        selectedOption={chartOption}  // The currently selected option
                        onSelect={handleChartChange}   // Function to toggle the switch on/off
                    />  
                    

                </div>

                <div className="legend mt-3" style={{position: "absolute"}}>
                    {filteredSeries.map((series, index) => (
                        <>
                        <div key={index} className="legend-item">
                            <span
                                className="legend-dot"
                                style={{ backgroundColor: series.color }}
                            ></span>
                            <span className="legend-name">{series.name}</span>
                            <span className="legend-value" style={{ color: series.color, marginLeft: "8px" }}>
                            {series.data[series.data.length - 1]?.toFixed(2)}%
                            </span>
                        </div>
                        </>
                    ))}
                </div>
                
                <Chart options={chartOptions} series={filteredSeries} type={chartType} height="85%" />
            </Card.Body>
        </Card>
    );
};

export default AiPerformance;
