// CustomDropdown.js
import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import prop-types for better type checking

const CustomDropdown = ({ options, selectedOption, onSelect, width }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        onSelect(option);
        setIsOpen(false);
    };

    const dropdownStyle = {
        width: width || '150px', // If no width is passed, use default 200px
    };

    return (
        <div className="custom-dropdown" style={dropdownStyle}>
            <button className="select-btn" onClick={handleToggle}>
                {selectedOption} <img src={`${process.env.PUBLIC_URL}/images/down 2.svg`} alt="icon" className="float-right mt-1" />
            </button>
            {isOpen && (
                <div className="select-options">
                    {options.map((option) => (
                        <div
                            key={option}
                            className="option"
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

// Define PropTypes for the component
CustomDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    width: PropTypes.string,
};

export default CustomDropdown;