import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import CropOverView from "./components/CropOverview";
import Estimate from "./components/Estimate";
import SupplyPrediction from "./components/SupplyPrediction";
import BidAsk from "./components/BidAsk";
import SymbolDetailsAnalyze from "./components/SymbolDetailsAnalyze";
import TradingViewContainer from "./components/TradingView";


const Analyze = () => {

    return (
        <>
            <Container fluid className="mb-3">
                <Row>
                    <Col md={6} lg={3}>
                        <CropOverView />
                        <BidAsk />
                    </Col>
                    <Col md={6}>
                        <Estimate />
                    </Col>
                    <Col md={6} lg={3}>
                        <SymbolDetailsAnalyze />
                        <TradingViewContainer />
                        <SupplyPrediction />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Analyze;