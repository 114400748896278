import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Table, NavDropdown } from 'react-bootstrap';
import { TextField, MenuItem, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import { AuthContext } from '../context/AuthContext';
import { showSweetAlert } from './SweetAlert';
import Skeleton from 'react-loading-skeleton';

function SettingControls() {
    const [fromAccount, setFromAccount] = useState('AI Account');
    const [toAccount, setToAccount] = useState('AI Account');
    const [amount, setAmount] = useState('');
    const [stoploss, setStopLoss] = useState('15');
    const [takeprofit, setTakeProfit] = useState('15');
    const [warning, setWarning] = useState('5');
    const [selectedButton, setSelectedButton] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const { aioperation, setAiOperation, aiSettings, userData, preferenceData,editAllocations,settingParams, addAllocations, setSelectedNameVariable, symbolTradeDate, fetchSymbolDetails, deleteAllocations, availableBalance, setAvailableBalance, allocationPrefLoading, setAllocationPrefLoading } = useContext(AuthContext)
    const [sliderValue, setSliderValue] = useState(aioperation);
    const [isDropDown, setisDropDown] = useState(false); // Track editing row
    const [isAddDropDown, setisAddDropDown] = useState(false); // Track editing row
    const [dateDropdownIndex, setDateDropdownIndex] = useState(null);
    const [nameDropdownIndex, setNameDropdownIndex] = useState(null);
    const [options, setOptions] = useState([]);
    const [portion, setPosition] = useState([]);
    const [nameoptions] = useState(['Corn', 'Soybeans', 'Gold', 'Crude Oil']);
    const [selectedDates, setSelectedDates] = useState({});
    const [selectedName, setSelectedName] = useState({});
    // const [availableBalance, setAvailableBalance] = useState();
    const [newRows, setNewRows] = useState([]); // State for newly added rows
    const [newRowNameDropdownIndex, setNewRowNameDropdownIndex] = useState(null);
    const [selectedNameForNewRows, setSelectedNameForNewRows] = useState({});
    const [newRowDateDropdownIndex, setNewRowDateDropdownIndex] = useState(null);
    const [selectedDateForNewRows, setSelectedDateForNewRows] = useState({});
    const [allocationAmount, setAllocationAmount] = useState([]);
    const [newAllocationAmount, setNewAllocationAmount] = useState([]);
    const [aiInvestedAmout, setAiInvestedAmout] = useState("");
    const nameToCodeMap = {
        'Corn': 'ZC',
        'Soybeans': 'ZS',
        'Gold': 'GC',
        'Crude Oil': 'CL',
    };
    const [updatedRowIndex, setUpdatedRowIndex] = useState(null);
    const [setUsedDates] = useState({});
    const [selectedMonthsMap, setSelectedMonthsMap] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (symbolTradeDate) {
            const symbol = symbolTradeDate[0]['symbol'];
    
            const monthsList = symbolTradeDate.flatMap(item =>
                item.sections
                    .filter(section => section.secType === "FUT" && section.months)
                    .map(section => section.months.split(';'))
            ).flat().filter((value, index, self) => self.indexOf(value) === index);
    
            // Check if we need to update the options at the specific index
            if (updatedRowIndex >= 0) {
                const preference = selectedName[updatedRowIndex];
                
                if (preference === symbol) {
                    console.log(`Updating options for row index ${updatedRowIndex}`);
                    
                    const updatedOptions = [...options];
                    updatedOptions[updatedRowIndex] = monthsList;
                    setOptions(updatedOptions);
                }
            }
        }
    }, [symbolTradeDate, preferenceData, updatedRowIndex, options, selectedName]);
    
    // console.log(preferenceData);

    const handleToggle = (isOpen) => {
        setShowDropdown(isOpen);
    };

    useEffect(() => {
        setSliderValue(aioperation);
    }, [aioperation]);

    const handleSvgClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleFromAccountChange = (event) => {
        setFromAccount(event.target.value);
    };

    const handleToAccountChange = (event) => {
        setToAccount(event.target.value);
    };

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handleLossChange = (event) => {
        const value = event.target.value.replace('%', '').trim()
        setStopLoss(value);
        const updatedSettings = {
            ...(Object.keys(settingParams).length > 0
              ? settingParams
              : userData?.ai_settings || {}), // Use existing settings or fallback
            stopLoss: value, // Update stopLoss dynamically
          };
        debouncedUpdateSettings(updatedSettings);
    };

    const handleProfitChange = (event) => {
        const value = event.target.value.replace('%', '').trim()
        setTakeProfit(value);
        const updatedSettings = {
            ...(Object.keys(settingParams).length > 0
              ? settingParams
              : userData?.ai_settings || {}),
            takeProfit: value,
        };
        debouncedUpdateSettings(updatedSettings);
    };

    const handleWarningChange = (event) => {
        const value = event.target.value.replace('%', '').trim()
        setWarning(value);
        const updatedSettings = {
            ...(Object.keys(settingParams).length > 0
              ? settingParams
              : userData?.ai_settings || {}),
            warning: value,
        };
        debouncedUpdateSettings(updatedSettings);
    };

    useEffect(() => {
        if (userData && userData.ai_settings) {
            setStopLoss(userData.ai_settings.stopLoss || '15'); 
            setTakeProfit(userData.ai_settings.takeProfit || '15');
            setWarning(userData.ai_settings.warning || '5');
            setAvailableBalance(userData.ai_settings.amount || '');
        }
    }, [userData, setStopLoss, setTakeProfit, setWarning, setAvailableBalance]);    

    useEffect(() => {
        if (preferenceData && preferenceData.length > 0) {
            const positions = preferenceData.map((preference) => preference.position);
            const amountData = preferenceData.map((preference) => preference.amount);
            const dateData = preferenceData.map((preference) => preference.date);
            const nameData = preferenceData.map((preference) => preference.name);
            setPosition(positions);
            setAllocationAmount(amountData);
            setSelectedDates(dateData);
            setSelectedName(nameData);
        }
    }, [preferenceData]);    

    const calculateTotalAllocation = () => {
        return portion.reduce((total, value) => total + (parseFloat(value) || 0), 0);
    };
    
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedUpdateSettings = debounce(async (params) => {
        try {
            await aiSettings(params);
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    }, 1000); 

    const handleSliderChange = (event, newValue) => {
        setAiOperation(newValue); 
        const updatedSettings = {
            ...(Object.keys(settingParams).length > 0 ? settingParams : userData?.ai_settings || {}),
            ai_operation: newValue, // Update the `ai_operation` value
        };
        debouncedUpdateSettings(updatedSettings);
    };

    const handleSubmit = async () => {
        if (isSubmitDisabled) {
            return;
        }
        // Parse the input amount entered by the user
        const inputAmount = parseFloat(amount || 0); // Amount entered by the user

        let updatedAmount = availableBalance; // Use the availableBalance directly

        if (selectedButton === 'Deposit') {
            updatedAmount = availableBalance + inputAmount; // Add amount for deposit
        } else if (selectedButton === 'Withdraw') {
            updatedAmount = availableBalance - inputAmount; // Subtract amount for withdrawal
        }
        // Ensure balance is not negative after withdrawal
        if (updatedAmount < 0) {
            alert("Insufficient balance for withdrawal!");
            return;
        }

        const updatedSettings = {
            ...(Object.keys(settingParams).length > 0
              ? settingParams
              : userData?.ai_settings || {}), // Use existing settings or fallback
              fromAccount: fromAccount, 
              toAccount: toAccount,
              amount: updatedAmount,
              depositwithdraw: selectedButton
        };

        try {
            await aiSettings(updatedSettings);
            setAvailableBalance(updatedAmount);
            setAmount(''); // Reset input fields
            setSelectedButton(null);
        } catch (error) {
            console.error('Error submitting order:', error);
            alert('Failed to submit order.');
        }
    };

    const isSubmitDisabled = !amount || !toAccount || !fromAccount || !selectedButton;

    const handleEditClick = async () => {
        setisDropDown(true); // Toggle editing state
        setLoading(true); 
        setAllocationPrefLoading(true);
        console.log(selectedName);
        if (preferenceData && preferenceData.length > 0) {
            const initialSelectedNames = {};
            let newOptions = []
            for (let index = 0; index < preferenceData.length; index++) {
                // console.log(index)
                const preference = preferenceData[index];
                try {
                    let symbolData = await fetchSymbolDetails(preference.name);
                    // console.log("Symbol Data:", symbolData); // Logs the resolved data
                    const monthsList = symbolData.flatMap(item =>
                        item.sections
                            .filter(section => section.secType === "FUT" && section.months)
                            .map(section => section.months.split(';'))
                    ).flat().filter((value, index, self) => self.indexOf(value) === index);

                    newOptions.push(monthsList)
                } catch (error) {
                    console.error(`Error fetching symbol details for ${preference.name}:`, error);
                }
                initialSelectedNames[index] = preference.name;
            }

            console.log(newOptions);
            
            setOptions(newOptions)
            setSelectedName(initialSelectedNames);
        }
        setLoading(false);
        setAllocationPrefLoading(false);
    };

    useEffect(() => {
        if (selectedName) {
            // console.log('Updated selectedName:', selectedName);
            // Perform any additional actions here after selectedName is updated
        }
    }, [selectedName]);
    
    const handleConfirmClick = () => {
        setisDropDown(false); // Toggle editing state
        try {
            // Prepare the data in the desired format and call editAllocations
            const updatedPreferences = preferenceData.map((preference, index) => {
                console.log(preference.id);
                
                const updatedData = {
                    id: preference.id,
                    name: selectedName[index] || preference.name, // Fallback to original if not selected
                    date: selectedDates[index] || preference.date, // Fallback to original if not selected
                    position: portion[index] || preference.position,
                    amount: allocationAmount[index] || preference.amount,
                };
                return updatedData;
            });
    
            updatedPreferences.forEach((preference) => {
                const {id ,name, date, position, amount } = preference;
                const response = editAllocations(name, date, position, amount, id);
                if(response.status === 200 ){
                    showSweetAlert('Success!', 'Allocation updated successfully', 'success');
                }
            });
        } catch (error) {
            // Handle any error that occurs during the API call
            console.error('Error updating allocations:', error);
        }
    };

    const handleDropdownToggle = (index) => {
        if (loading) return;
        setDateDropdownIndex(dateDropdownIndex === index ? null : index);
    };

    const handleNameDropdownToggle = (index) => {
        setNameDropdownIndex(nameDropdownIndex === index ? null : index);
    };

    useEffect(() => {
        const initializeSelectedMonthsMap = () => {
            const map = {};
    
            // Process existing preferenceData
            preferenceData.forEach((row) => {
                const { name: symbol, date: selectedMonth } = row; // Assuming row has 'date' property
                if (symbol && selectedMonth) {
                    map[symbol] = [...(map[symbol] || []), selectedMonth];
                }
            });
    
            // Process existing newRows
            newRows.forEach((row) => {
                const { name: symbol, date: selectedMonth } = row; // Assuming row has 'date' property
                if (symbol && selectedMonth) {
                    map[symbol] = [...(map[symbol] || []), selectedMonth];
                }
            });
    
            setSelectedMonthsMap(map); // Set the initial map
        };
    
        initializeSelectedMonthsMap();
    }, [preferenceData, newRows]);
    

    const handleDateSelection = (index, value) => {

        if (loading) return; 
        // Safely get the symbol and previous date
        const symbol = selectedName[index] || preferenceData[index]?.name;
        const previousDate = selectedDates[index];
    
        // Update selectedDates state
        setSelectedDates((prev) => {
            const updatedDates = { ...prev, [index]: value };
            // console.log("Updated Selected Dates:", updatedDates);
            return updatedDates;
        });
    
        setDateDropdownIndex(null);
    
        // Update selectedMonthsMap state
        setSelectedMonthsMap((prevMap) => {
            const updatedMap = { ...prevMap };
    
            // Remove the previous date if it exists in the map
            if (previousDate && updatedMap[symbol]?.includes(previousDate)) {
                console.log(`Removing ${previousDate} for symbol ${symbol}`);
                updatedMap[symbol] = updatedMap[symbol].filter((month) => month !== previousDate);
            }
    
            // Add the new date if not already present
            if (!updatedMap[symbol]?.includes(value)) {
                updatedMap[symbol] = [...(updatedMap[symbol] || []), value];
            }
    
            // console.log("Updated Map:", updatedMap);
            return updatedMap; // Return the updated map
        });
    };
    
    
    const getFilteredOptions = (index) => {
        const symbol = selectedName[index] || preferenceData[index].name;
        const alreadySelectedMonths = selectedMonthsMap[symbol] || [];
        return options[index]?.filter((month) => !alreadySelectedMonths.includes(month));
    };

    const getNewFilteredOptions = (index) => {
        const symbol = selectedNameForNewRows[index] || preferenceData[index].name;
        const alreadySelectedMonths = selectedMonthsMap[symbol] || [];
        return options[index]?.filter((month) => !alreadySelectedMonths.includes(month));
    };

    const handleNameSelection = (index, value) => {
        const nameData = nameToCodeMap[value];
    
        // Count occurrences of the name in the current selectedName state
        const nameCount = Object.values(selectedName).filter(name => name === nameData).length;
    
        console.log(selectedName);
        console.log(nameCount);
    
        if (nameCount >= 2) {
            showSweetAlert('Warning!', 'Only two records for the same name are allowed.', 'warning');
            // alert("Only two records for the same name are allowed.");
            return; // Exit the function without updating the state
        }
    
        // Update the selectedName state if the count condition is met
        setSelectedName((prev) => {
            return { ...prev, [index]: nameData };
        });
    
        setSelectedNameVariable(nameData);
        setNameDropdownIndex(null);
        setUpdatedRowIndex(index);
    };
    

    const handlePreferenceChange = (index, event) => {
        const inputValue = event.target.value;
        const value = parseFloat(inputValue) || 0; // Default to 0 if input is empty or invalid
    
        const totalAllocation = calculateTotalAllocation();
        const currentAllocation = portion[index] ? parseFloat(portion[index]) : 0;
    
        // Check if the new total exceeds 100%
        if (totalAllocation - currentAllocation + value > 100) {
            alert("Total allocation cannot exceed 100%");
            return;
        }
    
        setPosition((prevState) => {
            const newPosition = [...prevState];
            newPosition[index] = value; // Update value at the correct index
            return newPosition;
        });
    
        // Update the corresponding amount only if `availableBalance` and `value` are valid
        if (availableBalance && !isNaN(value)) {
            const calculatedAmount = (availableBalance * value) / 100;
            setAllocationAmount((prev) => ({
                ...prev,
                [index]: calculatedAmount.toFixed(2),
            }));
        } else if (inputValue === "") {
            // If input is cleared, reset the corresponding amount
            setAllocationAmount((prev) => ({
                ...prev,
                [index]: "0.00",
            }));
        }
    };
    

    const handleAddClick = () => {
        const newRow = { name: '', date: '', position: '', amount: '' };
        setNewRows((prevRows) => [...prevRows, newRow]);
        setisAddDropDown(true);
    };

    const handleAddConfirmClick = () => {
        try {
            // Merge selectedNameForNewRows and selectedDateForNewRows into newRows
            const mergedNewRows = newRows.map((row, index) => ({
                ...row,
                name: selectedNameForNewRows[index] || row.name,
                date: selectedDateForNewRows[index] || row.date,
                amount: newAllocationAmount[index] || row.amount,
            }));

            console.log(mergedNewRows);
            
    
            // Validate and call the `addAllocations` API for each new row
            mergedNewRows.forEach((row) => {
                const { name, date, position, amount } = row;
    
                // Add basic validation to ensure required fields are not empty
                if (!name || !date || !position || amount === undefined) {
                    console.error('Missing required fields:', row);
                    return;
                }
                // const nameData = nameToCodeMap[name]; 
                // Call addAllocations API
                const response = addAllocations(name, date, position, amount);
                console.log(response);
                
                // if(response.status === 200 ){
                //     showSweetAlert('Success!', 'Allocation added successfully', 'success');
                // }
            });
    
            // Clear the new rows and dropdown states
            setNewRows([]);
            setSelectedNameForNewRows({});
            setSelectedDateForNewRows({});
            setisAddDropDown(false);
    
            console.log('New rows successfully added!');
            showSweetAlert('Success!', 'Allocation added successfully', 'success');
        } catch (error) {
            console.error('Error saving new allocations:', error);
            showSweetAlert('Error!', 'Error saving new allocations', 'error');
        }
    };    

    const handleFieldChange = (index, field, value) => {
        // const inputValue = event.target.value;
        if (field === "position") {
            const totalAllocation = calculateTotalAllocation();
            const currentAllocation = newRows[index]?.position ? parseFloat(newRows[index].position) : 0;
    
            // Check if the new total exceeds 100%
            if (totalAllocation - currentAllocation + parseFloat(value) > 100) {
                alert("Total allocation cannot exceed 100%");
                return;
            }
        }
    
        setNewRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index][field] = value;
            return updatedRows;
        });
    
        // Update the corresponding amount only if `availableBalance` and `value` are valid
        if (availableBalance && !isNaN(value)) {
            const calculatedAmount = (availableBalance * value) / 100;
            setNewAllocationAmount((prev) => ({
                ...prev,
                [index]: calculatedAmount.toFixed(2),
            }));
        } else if (value === "") {
            // If input is cleared, reset the corresponding amount
            setNewAllocationAmount((prev) => ({
                ...prev,
                [index]: "0.00",
            }));
        }
    };

    const handleNameSelectionForNewRow = async (index, value) => {
        try {
            const nameData = nameToCodeMap[value];
    
            // Use a functional state update to get the latest state values
            const existingNameCount = (() => {
                const countInSelectedName = Object.values(selectedName).filter(name => name === nameData).length;
                const countInNewRows = Object.values(selectedNameForNewRows).filter(name => name === nameData).length;
                return countInSelectedName + countInNewRows;
            })();
    
            if (existingNameCount >= 2) {
                showSweetAlert('Warning!', 'Only two records for the same name are allowed', 'warning');
                return; // Exit if the name count exceeds 2
            }
    
            // Update the selectedNameForNewRows state
            setSelectedNameForNewRows((prev) => {
                const updatedSelectedName = { ...prev, [index]: nameData };
                return updatedSelectedName;
            });
    
            let symbolData = await fetchSymbolDetails(nameData);
            const monthsList = symbolData.flatMap(item =>
                item.sections
                    .filter(section => section.secType === "FUT" && section.months)
                    .map(section => section.months.split(';'))
            ).flat().filter((value, index, self) => self.indexOf(value) === index);
    
            setOptions((prevOptions) => {
                const updatedOptions = [...prevOptions];
                updatedOptions[index] = monthsList;
                return updatedOptions;
            });
    
            setNewRowNameDropdownIndex(null);
        } catch (error) {
            console.error(`Error fetching symbol details for ${value}:`, error);
        }
    };
    
    const handleNewRowNameDropdownToggle = (index) => {
        setNewRowNameDropdownIndex(newRowNameDropdownIndex === index ? null : index);
    };
    
    const handleDateSelectionForNewRow = (index, value) => {
        // Safely get the symbol
        const symbol = selectedNameForNewRows[index] || newRows[index]?.name;
    
        // Get the previous date from selectedDateForNewRows if available; otherwise, fallback to selectedDates
        const previousDate =
            selectedDateForNewRows[index] !== undefined
                ? selectedDateForNewRows[index]
                : selectedDates[index] !== undefined
                ? selectedDates[index]
                : null; // Fallback to null if no date exists in either state
    
        // Update selectedDateForNewRows state
        setSelectedDateForNewRows((prev) => {
            const updatedDates = { ...prev, [index]: value };
            return updatedDates;
        });
    
        setNewRowDateDropdownIndex(null);
    
        // Update selectedMonthsMap state
        setSelectedMonthsMap((prevMap) => {
            const updatedMap = { ...prevMap };
    
            // If the symbol already has data, ensure the 0th index is not replaced, but replace the 1st index
            if (updatedMap[symbol]) {
                if (previousDate && updatedMap[symbol].includes(previousDate) && previousDate !== value) {
                    console.log(`Replacing ${previousDate} with ${value} for symbol ${symbol} at index 1`);
                    
                    // Replace the 1st index if it exists, otherwise replace the 0th index
                    if (updatedMap[symbol].length > 1) {
                        updatedMap[symbol][1] = value; // Replace 1st index if it exists
                    } else {
                        // If only the 0th index exists, we can push the new date to the next index
                        updatedMap[symbol].push(value);
                    }
                } else if (!updatedMap[symbol].includes(value)) {
                    // Add new date if not already present
                    updatedMap[symbol].push(value);
                }
            } else {
                // Initialize the array if it doesn't exist and add the first date
                updatedMap[symbol] = [value];
            }
    
            return updatedMap;
        });
    
        // Update usedDates state
        setUsedDates((prev) => {
            const updated = { ...prev };
            if (!updated[symbol]) updated[symbol] = [];
            updated[symbol] = [...new Set([...updated[symbol], value])];
            return updated;
        });
    };
    
    const handleNewRowDateDropdownToggle = (index) => {
        setNewRowDateDropdownIndex(newRowDateDropdownIndex === index ? null : index);
    };

    const getName = (name) => {
        if(name === 'ZC'){
            return 'Corn'
        }else if(name === 'ZS'){
            return 'Soybeans'
        }else if(name === 'GC'){
            return 'Gold'
        }else if(name === 'CL'){
            return 'Crude Oil'
        }
    }

    const handleDeleteClick = (id) => {
        try{
            deleteAllocations(id);
            showSweetAlert('Success!', 'Allocation deleted successfully', 'success');
        }catch(e){
            console.log(`Failed to delete allocation : ${e}`);
            showSweetAlert('Error!', 'Error saving new allocations', 'error');
        }
    }

    useEffect(() => {
        if (userData) {
            setAiInvestedAmout((prev) => userData.aiAmountUsed.toFixed(2) || prev.toFixed(2));
        }
    }, [userData]);    
    
    return (
        <Card className="main-card mt-3 home-chart">
            <Card.Header className="pb-0">
                <div className="d-flex justify-content-between align-items-center mt-1">
                    <div className="d-flex align-items-center">
                        <h2 className="medium-heading mb-0">Settings Control</h2>
                        <div className='ai_block'>
                            <span className="ml-2">AI</span>
                        </div>
                    </div>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={handleSvgClick}
                            style={{ cursor: 'pointer' }}
                            >
                            <path d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="white" />
                            <path d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z" fill="white" />
                            <path d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z" fill="white" />
                        </svg>
                        <NavDropdown
                            show={showDropdown}
                            onToggle={handleToggle}
                            title=""
                            id="nav-dropdown"
                            align="end"
                            className="d-inline custom-nav-dropdown"
                            style={{ display: showDropdown ? 'block' : 'none' }}
                            >
                            {/* <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('watchlist')}>Close Window</NavDropdown.Item> */}
                        </NavDropdown>
                    </div>
                </div>
                <span>Totality</span>
            </Card.Header>

            <Card.Body className="pt-0 p-2 mt-1">
                <form>
                    <Row className="mb-2">
                        <Col sm={6}>
                            {/* Dropdown for From Account */}
                            <FormControl className="square-form-control m-0" sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    value={fromAccount}
                                    onChange={handleFromAccountChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className="square-select"
                                >
                                    <MenuItem value="AI Account"> From IRA Account </MenuItem>
                                    <MenuItem value="Saving Account"> From Saving Account </MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col sm={6}>
                            <FormControl className="square-form-control m-0" sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    value={toAccount}
                                    onChange={handleToAccountChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className="square-select"
                                >
                                    <MenuItem value="AI Account"> To AI Account </MenuItem>
                                    <MenuItem value="Saving Account"> From Saving Account </MenuItem>
                                </Select>
                            </FormControl>

                        </Col>
                    </Row>

                    <Row className="mb-2 control">
                        <Col sm={6}>
                            <FormControl className="square-form-control m-0" sx={{ m: 1, minWidth: 120 }}>
                                <TextField
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={handleAmountChange}
                                    fullWidth
                                    margin="normal"
                                    type="text"

                                />
                            </FormControl>

                        </Col>
                        <Col sm={3}>
                            <Button
                                variant="outlined"
                                className={`ai-btn w-100 ${selectedButton === 'Deposit' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('Deposit')}
                                style={{
                                    backgroundColor: selectedButton === 'Deposit' ? 'rgba(49, 124, 255, 0.20)' : 'transparent',
                                    color: selectedButton === 'Deposit' ? '#FFFFFF' : '#898889'
                                }}
                            >
                                Deposit
                            </Button>
                        </Col>
                        <Col sm={3}>
                            <Button
                                variant="outlined"
                                className={`ai-btn w-100 ${selectedButton === 'Withdraw' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('Withdraw')}
                                style={{
                                    backgroundColor: selectedButton === 'Withdraw' ? 'rgba(49, 124, 255, 0.20)' : 'transparent',
                                    color: selectedButton === 'Withdraw' ? '#FFFFFF' : '#898889'
                                }}
                            >
                                Withdraw
                            </Button>
                        </Col>
                    </Row>

                    <div className="row justify-content-center">
                        <button type="button" disabled={isSubmitDisabled} className="w-65 p-2 btn-primary" style={{
                            color: isSubmitDisabled ? '#000000' : '#FFFFFF', // White text when disabled
                            backgroundColor: isSubmitDisabled ? '#D3D3D3' : '#2979FF', // Disabled shade or primary color
                            cursor: isSubmitDisabled ? 'not-allowed' : 'pointer', // Change cursor style
                            opacity: isSubmitDisabled ? 0.6 : 1 ,
                            border: "unset"
                        }} onClick={handleSubmit}>Submit</button>
                    </div>
                    
                    <hr className="mt-2 mb-2" />
                    <div>
                        <div style={{padding: '0px 10px'}}>
                            <span>Risk Management Settings</span><br></br>
                            <span>AI operation style</span>
                            <Slider
                                aria-label="Custom marks"
                                className="ai-slider"
                                value={sliderValue}
                                onChange={handleSliderChange}
                                defaultValue={1.25}
                                min={0.25}
                                max={5.5}
                                step={0.25}
                                valueLabelDisplay="on"
                                sx={{
                                    "& .MuiSlider-track": {
                                        color: "#317CFF",
                                    },
                                    "& .MuiSlider-rail": {
                                        color: "#d3d3d3",
                                    },
                                    "& .MuiSlider-valueLabel": {
                                        display: "none",
                                        backgroundColor: "black",
                                        color: "white",
                                        borderRadius: "4px",
                                    },
                                    "&:hover .MuiSlider-valueLabel": {
                                        display: "block",
                                        backgroundColor: "black",
                                        color: "white",
                                    },
                                }}
                            />


                        </div>
                        
                        <Row style={{ 'marginTop': '-10px', 'marginBottom' : '8px'}}>
                            <Col sm={6} style={{ textAlign: 'left' }}>
                                <div>
                                    <span className="small-gray">Conservative</span>
                                </div>
                            </Col>
                            <Col sm={6} style={{ textAlign: 'right' }}>
                                <div>
                                    <span className="small-gray">Progressive</span>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-3 control align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Balance operated by AI</span>
                                </div>
                                <span className='small-gray'>
                                    {availableBalance !== undefined && typeof availableBalance === 'number'
                                        ? availableBalance.toFixed(2)
                                        : '0.00'} Available
                                </span>

                            </Col>
                            <Col sm={6}>
                                <FormControl className="square-form-control m-0" sx={{ minWidth: 120 }}>
                                    <TextField
                                        // value="$ 23,732.77"
                                        value={`$ ${aiInvestedAmout}`}
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                        InputProps={{
                                            readOnly: true,
                                        }}                                
                                    />
                                </FormControl>
                            </Col>
                        </Row>

                        <Row className="mb-3 align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Stop - loss limit</span>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <FormControl className="square-form-control m-0" sx={{ minWidth: 120 }}>
                                    <TextField
                                        value={`${stoploss} %`}
                                        onChange={handleLossChange}
                                        fullWidth
                                        margin="normal"
                                        type="text"                            
                                    />
                                </FormControl>
                            </Col>
                        </Row>

                        <Row className="mb-3 align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Take - profit limit</span>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <FormControl className="square-form-control m-0" sx={{ minWidth: 120 }}>
                                    <TextField
                                        value={`${takeprofit} %`}
                                        onChange={handleProfitChange}
                                        fullWidth
                                        margin="normal"
                                        type="text"                            
                                    />
                                </FormControl>
                            </Col>
                        </Row>

                        <Row className="mb-3 align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Warning percentage</span>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <FormControl className="square-form-control m-0" sx={{ minWidth: 120 }}>
                                    <TextField
                                        value={`${warning} %`}
                                        onChange={handleWarningChange}
                                        fullWidth
                                        margin="normal"
                                        type="text"                             
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                    </div>

                    <hr className="mt-2 mb-2" />
                    <div style={{paddingLeft: "3px", paddingRight: "3px"}}>
                        <Row>
                            <Col sm={6} style={{ textAlign: 'left' }}>
                                <div>
                                    <span>Allocation Preferences</span>
                                </div>
                            </Col>
                            <Col sm={6} style={{ textAlign: 'right', display:'flex', justifyContent:'end' }}>
                                <div>
                                    <span
                                        style={{ cursor: 'pointer', color: isAddDropDown ? '#317CFF' : 'inherit', marginRight: '18px' }}
                                        onClick={() => (newRows.length > 0 ? handleAddConfirmClick() : handleAddClick())}
                                    >
                                        {newRows.length > 0 ? 'Confirm Add' : 'Add'}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        className=""
                                        style={{ cursor: 'pointer',color: isDropDown ? '#317CFF' : 'inherit', }}
                                        onClick={() => isDropDown ? handleConfirmClick() : handleEditClick()} // Close any open dropdown
                                    >
                                        {isDropDown ? 'Confirm' : 'Edit'}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-responsive scrollbar-custom m-h-320" style={{minHeight: '200px'}}>
                            <Table hover className="transaction-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Date</th>
                                        <th>Portion</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody className={isDropDown ? 'tbody-gray' : ''}>
                                    {allocationPrefLoading ? (
                                    <tr>
                                        <td colSpan={4}>
                                        <Skeleton
                                            count={3}
                                            style={{ margin: '4px 2px', borderRadius: '3px' }}
                                            baseColor="rgba(137, 136, 137, 0.3)"
                                            highlightColor="rgba(176, 176, 176, 0.3)"
                                        />
                                        </td>
                                    </tr>
                                    ) :(preferenceData && preferenceData.length > 0) || newRows.length > 0 ? (
                                        <>
                                            {newRows.map((row, index) => (
                                                <tr key={`new-${index}`}>
                                                    <td>
                                                        <div style={{ display: 'flex' }}>
                                                            {getName(selectedNameForNewRows[index]) || (<span style={{ color: 'rgba(108, 117, 125, 0.6)' }}>Symbol</span>)}
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/images/down-arrow.png`}
                                                                alt="icon"
                                                                height='15px'
                                                                width='16px'
                                                                style={{
                                                                    display: isAddDropDown ? 'block' : 'none',
                                                                    color: isAddDropDown ? '#898889' : '',
                                                                    marginTop: '-3px',
                                                                    marginLeft: '2px',
                                                                }}
                                                                onClick={() => handleNewRowNameDropdownToggle(index)}
                                                            />
                                                        </div>
                                                        {newRowNameDropdownIndex  === index && (
                                                            <div className="select-preference-options">
                                                                {nameoptions.map((option, optIndex) => (
                                                                    <div
                                                                        key={optIndex}
                                                                        className="option"
                                                                        onClick={() => handleNameSelectionForNewRow(index, option)}
                                                                    >
                                                                        {option}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div style={{ display: 'flex' }}>
                                                            {selectedDateForNewRows[index] || (<span style={{ color: 'rgba(108, 117, 125, 0.6)' }}>MM YY</span>)}
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/images/down-arrow.png`}
                                                                alt="icon"
                                                                height='15px'
                                                                width='16px'
                                                                style={{
                                                                    display: isAddDropDown ? 'block' : 'none',
                                                                    color: isAddDropDown ? '#898889' : '',
                                                                    marginTop: '-3px',
                                                                    marginLeft: '2px',
                                                                }}
                                                                onClick={() => handleNewRowDateDropdownToggle(index)}
                                                                disabled={!getName(selectedNameForNewRows[index])} 
                                                            />
                                                        </div>
                                                        {newRowDateDropdownIndex === index && getName(selectedNameForNewRows[index]) &&(
                                                            <div className="select-preference-options scrollbar-custom">
                                                                {getNewFilteredOptions(index).map((option, optIndex) => (
                                                                    <div key={optIndex} className="option" onClick={() => handleDateSelectionForNewRow(index, option)}>
                                                                        {option}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className={isAddDropDown ? 'setting-td' : ''} style={{ paddingRight: '0px', paddingTop: isAddDropDown ?'4px' : '' }}>
                                                        {isAddDropDown ? (
                                                            <FormControl className="square-form-control-setting m-0">
                                                                <TextField
                                                                    placeholder='10.00'
                                                                    value={row.position}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    type="number"
                                                                    inputProps={{ className: 'no-arrows' }}
                                                                    onChange={(e) => handleFieldChange(index, 'position', e.target.value)}
                                                                />
                                                            </FormControl>
                                                        ) : (
                                                            "" 
                                                        )}
                                                    </td>
                                                    <td className={isAddDropDown ? 'setting-td' : ''} style={{ paddingRight: '0px', paddingTop: isAddDropDown ?'4px' : '' }}>
                                                        {isAddDropDown ? (
                                                            newAllocationAmount[index]
                                                        ) : (
                                                            "" 
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                            {preferenceData && preferenceData.length > 0 && preferenceData.map((preference, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div style={{ display: 'flex' }}>
                                                            {getName(selectedName[index]) || getName(preference.name)}
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/images/down-arrow.png`}
                                                                alt="icon"
                                                                height='15px'
                                                                width='16px'
                                                                style={{
                                                                    display: isDropDown ? 'block' : 'none',
                                                                    color: isDropDown ? '#898889' : '',
                                                                    marginTop: '-3px',
                                                                    marginLeft: '2px',
                                                                    opacity: selectedName[index] && !loading ? 1 : 0.5,
                                                                    pointerEvents: loading ? 'none' : 'auto',
                                                                }}
                                                                onClick={() => !loading && handleNameDropdownToggle(index)}
                                                            />
                                                        </div>
                                                        {nameDropdownIndex === index && (
                                                            <div className="select-preference-options">
                                                                {nameoptions.map((option, optIndex) => (
                                                                    <div
                                                                        key={optIndex}
                                                                        className="option"
                                                                        onClick={() => handleNameSelection(index, option)}
                                                                    >
                                                                        {option}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div style={{ display: 'flex' }}>
                                                            {selectedDates[index] || preference.date}
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/images/down-arrow.png`}
                                                                alt="icon"
                                                                height='15px'
                                                                width='16px'
                                                                style={{
                                                                    display: isDropDown ? 'block' : 'none',
                                                                    color: isDropDown ? '#898889' : '',
                                                                    marginTop: '-3px',
                                                                    marginLeft: '2px',
                                                                    opacity: selectedName[index] ? 1 : 0.5,
                                                                }}
                                                                onClick={() => selectedName[index] && handleDropdownToggle(index)} 
                                                                disabled={!getName(selectedName[index])} 
                                                            />
                                                        </div>
                                                        {dateDropdownIndex === index && getName(selectedName[index]) && (
                                                            <div className="select-preference-options scrollbar-custom">
                                                                {getFilteredOptions(index).map((option, optIndex) => (
                                                                    <div key={optIndex} className="option" onClick={() => handleDateSelection(index, option)}>
                                                                        {option}
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className={isDropDown ? 'setting-td' : ''} style={{ paddingRight: '0px', paddingTop: isDropDown ?'4px' : '' }}>
                                                        {isDropDown ? (
                                                            <FormControl className="square-form-control-setting m-0">
                                                                <TextField
                                                                    value={portion[index]}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    type="float"
                                                                    inputProps={{ className: 'no-arrows' }}
                                                                    onChange={(event) => handlePreferenceChange(index, event)}
                                                                />
                                                            </FormControl>
                                                        ) : (
                                                            portion[index] !== undefined ? `${portion[index].toFixed(2)}%` : '0.00%'
                                                        )}
                                                    </td>
                                                    <td>
                                                        {allocationAmount[index]}
                                                    </td>
                                                    <td style={{
                                                        display: isDropDown ? 'table-cell' : 'none',
                                                        paddingLeft: '0px'
                                                    }}>
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/images/close.png`}
                                                            alt="icon"
                                                            height='12px'
                                                            width='12px'
                                                            style={{
                                                                color: isDropDown ? '#898889' : '',
                                                                marginLeft: '-10px',
                                                                marginTop: '-1px'
                                                            }}
                                                            onClick={() => handleDeleteClick(preference.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No data available</td>
                                        </tr>
                                    )}
                                </tbody>

                            </Table>
                        </div>
                    </div>
                </form>
            </Card.Body>
        </Card>
    );
}

export default SettingControls;