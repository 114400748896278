
import React, { Component, useContext, useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContext';

class TradingView extends Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.widgetLoaded = false;
  }

  componentDidMount() {
    if (!this.widgetLoaded) {
      this.loadTradingViewWidget();
    }
  }
  

  componentDidUpdate(prevProps) {
    if (prevProps.symbol !== this.props.symbol || prevProps.chartHeight !== this.props.chartHeight) {
      this.widgetLoaded = false;  // Reset flag
      this.loadTradingViewWidget();
    }
  }

  loadTradingViewWidget() {
    if (!this._ref.current || this.widgetLoaded) return;

    this._ref.current.innerHTML = '';  // Clear previous widget

    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
    // script.async = true;

    script.innerHTML = JSON.stringify({
      symbol: this.props.symbol,
      width: '100%',
      height: this.props.chartHeight,
      locale: 'en',
      dateRange: '12M',
      colorTheme: 'dark',
      isTransparent: true,
      autosize: true,
      largeChartUrl: '',
    });

    setTimeout(() => {
      if (this._ref.current) {
        this._ref.current.appendChild(script);
        this.widgetLoaded = true;  // Mark as loaded
      }
    }, 100);
  }

  render() {
    return (
      <div>
        <Card className="main-card mt-3">
          <Card.Header className="pb-0">
            <div className="d-flex justify-content-between mt-1">
              <h2 className="medium-heading mb-0"> Historical Price </h2>
            </div>
          </Card.Header>
          <Card.Body className="pt-2">
            <div className="tradingview-widget-container" ref={this._ref}></div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}


// TradingViewContainer will consume the symbol from AuthContext
const TradingViewContainer = () => {
    const { tradingviewsymbol } = useContext(AuthContext); // Get symbol from AuthContext
    const [chartHeight, setChartHeight] = useState(210); 
    
        useEffect(() => {
          const handleResize = () => {
            const height = window.innerHeight;
              if (height <= 800) {
                setChartHeight(170);
            } 
            else if (height <= 880) {
                setChartHeight(200);
            } 
            else if(height>= 900){
                setChartHeight(230);
            }
            else {
                setChartHeight(240); // Default height or adjust for other conditions
            }
          };
      
          handleResize(); // Set initial height
          window.addEventListener('resize', handleResize);
      
          return () => {
              window.removeEventListener('resize', handleResize);
          };
      }, []);
  
    return <TradingView symbol={tradingviewsymbol} chartHeight={chartHeight} />; // Pass the symbol to TradingView component
};

export default TradingViewContainer;
