import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useLocation } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { AuthContext } from '../context/AuthContext';

const UpdateEmail = ({ open, onClose, onUpdate, currentemail }) => {
    const location = useLocation();
    const initialEmail = location.state?.email || currentemail || '';
    const initialNewEmail = location.state?.newemail || '';
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState(initialEmail);
    const [newemail, setNewEmail] = useState(initialNewEmail);
    const [code, setCode] = useState('');
    const [seconds, setSeconds] = useState(60);
    const [showPrimaryButton, setShowPrimaryButton] = useState(false);
    const [errors, setErrors] = useState({ email: '', newemail: '', code: '' });
    const [codeError, setCodeError] = useState(false);
    const [verified, setVerified] = useState(false); // New state for verification dialog
    const { initEmailChange } = useContext(AuthContext); // Access the context
    const { changeEmail } = useContext(AuthContext); // Access the context
    const [loading, setLoading] = useState(false);
    const [step2loading, setstep2Loading] = useState(false);

    // console.log(currentemail);

    useEffect(() => {
        if (currentemail) {
            setEmail(currentemail);
        }
    }, [currentemail]);

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        let valid = true;

        let newErrors = { ...errors };

        if (!email) {
            console.log('no email');
            newErrors.email = 'Email is required.';
            valid = false;
        } else if (!newemail) {
            console.log('no email');
            newErrors.newemail = 'Email is required.';
            valid = false;
        }else {
            newErrors.email = '';
            newErrors.newemail = '';
        }

        setErrors(newErrors);
        setLoading(true);
        if (valid) {
            const response = await initEmailChange(newemail);
            if(response === "OTP sent to new email address"){
                setLoading(false);
            }
            
            setStep(2);
            setSeconds(60);
            setShowPrimaryButton(false);
        }
    };

    const handleCodeSubmit = async (event) => {
        event.preventDefault();
        setstep2Loading(true);
        let newErrors = { ...errors };
        try {
            const response = await changeEmail(code, newemail);
            
            // Assuming `changeEmail` returns a response with a success status
            if (response) {
                // Check if the response indicates success (you may want to adjust this based on your API)
                if (response === 'Email change successful') {
                    onClose();
                    setVerified(true);
                } else {
                    setCodeError(true); // Show error for the code input
                    newErrors.code = 'Invalid or Expired Code!';
                }
            } else {
                setCodeError(true);
                newErrors.code = 'Invalid or Expired Code!';
            }
        } catch (error) {
            console.error("Error during code submission:", error);
            newErrors.code = 'Invalid or Expired Code!';
            setCodeError(true); // Show error for the code input
        }finally {
            setstep2Loading(false);
            setErrors(newErrors);
        }
    };

    const handleNameSubmit = async (event) => {
        onUpdate("email", newemail); 
    }

    const handleResendCode = async () => {
        const response = await initEmailChange(newemail);
        setLoading(true);
        if(response === "OTP sent to new email address"){
            setLoading(false);
        }
            
        setStep(2);
        setSeconds(60);
        setShowPrimaryButton(false);
    };

    useEffect(() => {
        if (seconds > 0) {
            const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setShowPrimaryButton(true);
        }
    }, [seconds]);

    return(
        <>
        <Dialog open={open} onClose={onClose} aria-labelledby="settings-dialog-title" fullWidth PaperProps={{
            style: {
              width: '480px',
              maxWidth: '100%',
            },
          }} className="setting-dialog">
            <DialogTitle id="settings-dialog-title" className="d-flex justify-content-between mb-2">
                <div className="d-flex align-items-center">
                    <img style={{ float: 'none', height: "20px", width: "18px" }} src={`${process.env.PUBLIC_URL}/images/left.svg`} alt="left" className="close-icon" role="button" 
                    onClick={() => {
                        if (step === 2) {
                            setStep(1); // Go back to the first step if currently on the second step
                        } else {
                            onClose(); // Close the dialog if on the first step
                        }
                    }} />
                    <span style={{marginLeft:"10px"}}>Update Email Address</span>
                </div>
              <img style={{ float: 'none', height: "22px", width: "20px" }} src={`${process.env.PUBLIC_URL}/images/close.png`} alt="close" className="close-icon" role="button" onClick={onClose} />
            </DialogTitle>
            <DialogContent>
                <p className="" style={{fontSize: "13px"}}>Please enter your new email address, and we will send a verification code to verify it</p>
                {step === 1 ? (
                        <Form onSubmit={handleEmailSubmit}>
                            <div className="mb-2">
                                <span style={{fontSize: "16px"}}>Current Email Address</span>
                            </div>
                            <Form.Group controlId="formBasicEmail" className="control">
                                <FormControl className="square-form-control-update m-0" sx={{ m: 1, minWidth: 120 }}>
                                    <TextField
                                        placeholder=" "
                                        value={email}
                                        fullWidth
                                        margin="normal"
                                        type="email"
                                        readOnly
                                    />
                                </FormControl>
                            </Form.Group>
                            {errors.email && <div className="text-danger mt-1">{errors.email}</div>}

                            <div className="mt-3">
                                <div className="mb-2">
                                    <span style={{fontSize: "16px"}}>New Email Address</span>
                                </div>
                                <Form.Group controlId="formBasicEmail" className="control">
                                    <FormControl className="square-form-control-update m-0" sx={{ m: 1, minWidth: 120 }}>
                                        <TextField
                                            placeholder="Please enter your new email address"
                                            value={newemail}
                                            onChange={(e) => setNewEmail(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                            type="email"
                                        />
                                    </FormControl>
                                </Form.Group>
                                {errors.newemail && <div className="text-danger mt-1">{errors.newemail}</div>}
                            </div>
                            
                            <div className="mt-2 row justify-content-center">
                                <Button variant="primary" type="submit" style={{backgroundColor: '#48494C', textTransform: 'none'}} className="mt-5 w-55 p-2 theme-btn-email" disabled={loading}>
                                {loading ? 'Sending...' : 'Send code'}
                                </Button>
                            </div>
                        </Form>
                    ) : step === 2 ? (
                        <Form onSubmit={handleCodeSubmit}>
                            <div className="mb-2">
                                <span style={{fontSize: "16px"}}>New Email Address</span>
                            </div>
                            <Form.Group controlId="formBasicEmail" className="control">
                                <FormControl className="square-form-control-update m-0" sx={{ m: 1, minWidth: 120 }}>
                                    <TextField
                                        placeholder="Please enter your new email address"
                                        value={newemail}
                                        onChange={(e) => setNewEmail(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                        type="email"
                                        readOnly
                                    />
                                </FormControl>
                            </Form.Group>

                            <div className="mb-2 mt-3">
                                <span style={{fontSize: "16px"}}>2FA Code</span>
                            </div>
                            <Form.Group controlId="formBasicCode" className="control">
                                <FormControl className={`${codeError ? 'is-invalid' : ''} square-form-control-update m-0`} sx={{ m: 1, minWidth: 120 }}>
                                    <TextField
                                        placeholder="Enter the 6 digit code here"
                                        value={code}
                                        onChange={(e) => {
                                            setCode(e.target.value);
                                            setCodeError(false);
                                        }}
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                    />
                                </FormControl>

                                {errors.code && <div className="text-danger mt-1">{errors.code}</div>}
                            </Form.Group>

                            <div>
                                <div className="mt-2 row justify-content-center">
                                    <Button variant="primary" type="submit" style={{backgroundColor: '#317CFF',textTransform: 'none'}} className="mt-5 w-55 p-2 theme-btn-email" disabled={step2loading}>
                                        {step2loading ? 'Submitting...' : 'Submit'}
                                    </Button>
                                </div>
                                {!showPrimaryButton ? (
                                    <div className="row justify-content-center" disabled={seconds > 0}>
                                        <Button variant="secondary" style={{backgroundColor: '#48494C',textTransform: 'none'}} className="mt-3 w-55 p-2 theme-btn-resend">
                                            Resend code ({seconds}s)
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="row justify-content-center" disabled={seconds > 0}>
                                        <Button variant="primary" type="button" onClick={handleResendCode} style={{backgroundColor: '#317CFF', textTransform: 'none'}} className="mt-3 w-55 p-2 theme-btn-resend">
                                            {loading ? 'Resending...' : 'Resend code'}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Form>
                    ) : (
                        <div className="text-center mt-3">
                            <div >
                                <Button type="button" className="m-3 w-100 theme-btn" style={{backgroundColor: "transparent",border: "none",}}>
                                </Button>
                            </div>
                        </div>
                    )}
            </DialogContent>
      
          </Dialog>

           {/* Verification Success Dialog */}
           <Dialog 
                open={verified} 
                onClose={() => setVerified(false)} 
                aria-labelledby="settings-dialog-title" 
                fullWidth 
                PaperProps={{
                    style: {
                        width: '480px',
                        maxWidth: '100%',
                    },
                }} 
                className="setting-dialog"
            >
                <DialogTitle id="settings-dialog-title" className="d-flex justify-content-end mb-4 mt-2">
                    <img 
                        style={{ float: 'right', height: "22px", width: "20px" }} 
                        src={`${process.env.PUBLIC_URL}/images/close.png`} 
                        alt="close" 
                        className="close-icon" 
                        role="button" 
                        onClick={() => setVerified(false)} 
                    />
                </DialogTitle>
                <DialogContent >
                    <Row className="text-center">
                        <Col sm={12}>
                            <img 
                            style={{ height: "120px", width: "120px"}} 
                            src={`${process.env.PUBLIC_URL}/images/green_check.svg`} 
                            alt="green_check" 
                            className="mb-2" 
                            />
                        </Col>
                        <Col sm={12}>
                            <p style={{ fontSize: '20px', margin: '10px 0' }}>Email updated successfully!</p>
                        </Col>
                    </Row>
                    
                    <Form onSubmit={handleNameSubmit}>
                        <div className="mt-3 mb-1 row justify-content-center">
                            <Button variant="primary" type="submit" style={{backgroundColor: '#317CFF', textTransform: 'none' }} className="mt-5 w-55 p-2 theme-btn-email" onClick={() => setVerified(false)}>
                                Finish
                            </Button>
                        </div>
                    </Form>
                    
                </DialogContent>
            </Dialog>
      </>
    );
};

export default UpdateEmail;
