import React, { useState, useContext } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Policy from "./Policy";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";

const Login = () => {
    const location = useLocation();
    const initialEmail = location.state?.email || '';
    const [passwordShown, setPasswordShown] = useState(false);
    const [email, setEmail] = useState(initialEmail);
    const [password, setPassword] = useState('');
    const [trustDevice, setTrustDevice] = useState(false);
    const [errors, setErrors] = useState({ email: '', password: '', invalid: '' });
    const [showPolicy, setShowPolicy] = useState(false);
    const { login } = useContext(AuthContext);
    const [loading, setLoading] = useState(false); // Loading state

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors({ email: '', password: '', invalid: '' });
    
        // Check required fields
        if (!email || !password) {
            setErrors({ ...errors, invalid: 'Email and Password are required.' });
            return;
        }
    
        setLoading(true); // Set loading to true when API call starts
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const data = response.data;
    
            if (response.status === 200 && data.accessToken) {
                login(data.accessToken, data.refreshToken, data.userId);
            } else {
                // Handle case where API response indicates invalid credentials
                setErrors({ ...errors, invalid: data.message || 'Invalid email or password.' });
            }
        } catch (error) {
            // Check if the error response exists and contains a message
            if (error.response && error.response.data) {
                setErrors({ ...errors, invalid: error.response.data.message || 'Invalid email or password.' });
            } else {
                setErrors({ ...errors, invalid: 'Failed to login. Please try again later.' });
            }
        } finally {
            setLoading(false); // Reset loading state after API call
        }
    };

    const handleShowPolicy = () => {
        setShowPolicy(true);
    };

    const handleHidePolicy = () => {
        setShowPolicy(false);
    };

    return (
        <div className="login-container">
            {showPolicy ? (
                <Policy handleClose={handleHidePolicy} />
            ) : (
                <Card className="login-card gradient-border">
                    <Card.Body>
                        <div className="text-center mb-5 mt-2">
                            <img src={`${process.env.PUBLIC_URL}/images/logo.webp`} className="App-logo" alt="logo" />
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail" className="floating-label-group">
                                <Form.Control
                                    type="email"
                                    className="form-input"
                                    placeholder=" "
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                />
                                <label className={email ? 'floating-label float' : 'floating-label'}>Email</label>
                            </Form.Group>
                            {errors.email && <div className="text-danger mt-1">{errors.email}</div>}
                            <Form.Group controlId="formBasicPassword" className="mt-3 floating-label-group">
                                <div className="password-wrapper">
                                    <Form.Control
                                        type={passwordShown ? 'text' : 'password'}
                                        className="form-input"
                                        placeholder=" "
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <label className={password ? 'floating-label float' : 'floating-label'}>Password</label>
                                    <span className="toggle-password" onClick={togglePasswordVisibility}>
                                        {passwordShown ? <img src={`${process.env.PUBLIC_URL}/images/eye_visible.png`} alt="eye" /> : <img src={`${process.env.PUBLIC_URL}/images/eye_hide.png`} alt="eye" />}
                                    </span>
                                </div>
                            </Form.Group>
                            {errors.password && <div className="text-danger mt-1">{errors.password}</div>}
                            {errors.invalid && <div className="text-danger mt-1">{errors.invalid}</div>}
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <Link to="/forgot-password" state={{ email }} className="forgot-password">Forgot password?</Link>
                            </div>
                            <Form.Group controlId="formBasicCheckbox" className="mt-3">
                                <Form.Check type="checkbox" label="Trust this device" checked={trustDevice} onChange={(e) => setTrustDevice(e.target.checked)} />
                            </Form.Group>
                            <div>
                                <Button 
                                    variant="primary" 
                                    type="submit" 
                                    className="mt-3 w-100 p-2 theme-btn"
                                    disabled={loading} // Disable button if loading
                                >
                                    {loading ? "Logging In..." : "Log In"} {/* Change button text based on loading state */}
                                </Button>
                                <div className="text-center mt-3">
                                    <span className="privacy-policy" role="button" onClick={handleShowPolicy}>Privacy policy</span>
                                </div>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            )}
        </div>
    );
};

export default Login;
