import { React, useContext} from 'react'
import { Card } from 'react-bootstrap'
import { AuthContext } from '../context/AuthContext';

const SupplyPrediction = () => {
    const { latestWasdeData } = useContext(AuthContext);
    
    // Ensure that latestWasdeData is available
    if (!latestWasdeData) {
        return (
        <div>
            <Card className="main-card mt-3">
            <Card.Header className="pb-0">
                <h2 className="medium-heading mb-0">Loading Supply Prediction...</h2>
            </Card.Header>
            <Card.Body>
                <p>Loading data...</p>
            </Card.Body>
            </Card>
        </div>
        );
    }

    const total = latestWasdeData?.cy_production + latestWasdeData?.cy_use || 0; // Dynamically calculate total
    const supply = latestWasdeData?.cy_production || 0; // Supply value
    const use = latestWasdeData?.cy_use || 0; // Use value

    // Calculate widths as percentages
    const supplyWidth = (supply / total) * 100;
    const useWidth = (use / total) * 100;
    
  return (
    <div>
      <Card className="main-card mt-3">
        <Card.Header className="pb-0">
          <div className="d-flex justify-content-between mt-1">
            <h2 className="medium-heading mb-0">Supply Prediction</h2>
          </div>
        </Card.Header>
        <Card.Body className="pt-2">
            <div className='supply-container'>
                <div className='acreage-yield-container'>
                    <div className='first-row-container'>
                        <div className='gray-text-1'>Acreage Information</div>
                        <div className='column'>
                            <div className='flex-container'>
                                <div className='text-normal text-width'>Planted</div>
                                <div className='value-container'>
                                    <div className='value-fill planted' style={{ width: `${latestWasdeData?.cy_ac_planted}%` }}></div>
                                    <span className='value-text'>{latestWasdeData?.cy_ac_planted}</span>
                                </div>
                            </div>
                            <div className='flex-container'>
                                <div className='text-normal text-width'>Harvested</div>
                                <div className='value-container'>
                                    <div className='value-fill harvested' style={{ width: `${latestWasdeData?.cy_ac_harvested}%` }}></div>
                                    <span className='value-text'>{ latestWasdeData?.cy_ac_harvested }</span>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className='vertical'></div>
                    <div className='flex-col-1-container'>
                        <div className='gray-text-1'>Yield Information</div>
                        <div className='column-second'>
                            <div className='text-normal'>Yield per Harvested Acre</div>
                            <div className='yield-value'>{latestWasdeData?.cy_yield} bu</div>
                        </div>
                    </div> 
                </div>
                <hr />
                <div className='supply-demand-container'>
                    <div className='first-row-container'>
                        <div className='gray-text-1'>Supply and Demand</div>
                        <div className='column-sd'>
                            <div className="bar-supply-container">
                                <div
                                className="supply-bar"
                                style={{ width: `${supplyWidth}%` }}
                                >
                                Supply
                                </div>
                                <div
                                className="use-bar"
                                style={{ width: `${useWidth}%` }}
                                >
                                Use
                                </div>
                            </div>
                            <div className="values-container">
                                <div className="supply-value">{supply.toLocaleString()}</div>
                                <div className="use-value">{use.toLocaleString()}</div>
                            </div>
                        </div>
                        
                    </div> 
                    <div className='vertical'></div>
                    <div className='flex-col-1-container'>
                        <div className='gray-text-1'>Predicted Price from USDA</div>
                        <div className='column-second'>
                            <div className='text-normal'>Avg.Farm Price</div>
                            <div className='yield-value'>$ {latestWasdeData?.cy_price} / bu</div>
                        </div>
                    </div>
                </div>
            </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default SupplyPrediction;
