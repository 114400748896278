import React, { useState } from 'react';
import { Card, ListGroup, Badge, NavDropdown } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useWidgetContext } from '../context/DragContext';

function NewsFeed() {
    const [selectedNews, setSelectedNews] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const { removeWidgetFromGrid } = useWidgetContext();

    const handleToggle = (isOpen) => {
        setShowDropdown(isOpen);
    };

    const handleSvgClick = () => {
        setShowDropdown(!showDropdown);
    };

    const [open, setOpen] = useState(false);
    const handleClickOpen = (item) => {
        setSelectedNews(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedNews(null);
    };

    const newsItems = [
        {
            id: 1,
            sentiment: '1.0',
            sentimentColor: 'success',
            title: "Gold rebounds as market's focus turns to US inflation data",
            description: "Gold prices rebounded on Monday after the previous metal's biggest daily drop in three and a half years in the last session",
            source: "Reuters",
            time: "1 hour ago",
            posted_by: "By Harshit Verma",
            posted_date: "June 10, 2024 2:32 PM EDT",
            updated_on: "Updated 24 days ago"
        },
        {
            id: 2,
            sentiment: '-0.2',
            sentimentColor: 'danger',
            title: "Gold prices drop ahead of US Fed outcome",
            description: "Gold prices: The Multi Commodity Exchange (MCX) gold futures for August 2024 expiry opened lower at $71251 per 10 grams.",
            source: "CNBCTV18",
            time: "14 hours ago",
            posted_by: "By Harshit Verma",
            posted_date: "June 10, 2024 2:32 PM EDT",
            updated_on: "Updated 24 days ago"
        },
        {
            id: 3,
            sentiment: '0.5',
            sentimentColor: 'success',
            title: "Gold prices hit a record high. What's behind the surge?",
            description: "The price of gold has climbed 17% in 2024. ByMax Zahn. April 12, 2024, 10:41 AM. 3:43. Sales of gold bars surge at Costco. A report from Wells",
            source: "ABD News",
            time: "April 12, 2024",
            posted_by: "By Harshit Verma",
            posted_date: "June 10, 2024 2:32 PM EDT",
            updated_on: "Updated 24 days ago"
        }
    ];

    return (
        <Card className="main-card mt-3">
            <Card.Header className="pb-0">
                <div className="d-flex justify-content-between mt-1">
                    <h2 className="medium-heading mb-0">News</h2>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={handleSvgClick}
                            style={{ cursor: 'pointer' }}
                            >
                            <path d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="white" />
                            <path d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z" fill="white" />
                            <path d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z" fill="white" />
                        </svg>
                        <NavDropdown
                            show={showDropdown}
                            onToggle={handleToggle}
                            title=""
                            id="nav-dropdown"
                            align="end"
                            className="d-inline custom-nav-dropdown"
                            style={{ display: showDropdown ? 'block' : 'none' }}
                            >
                            <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('news')}>Close Window</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="pt-0 pr-2 pb-0 scrollbar-custom w-t-div">
                <ListGroup variant="flush" className="news-list  pr-1">
                    {newsItems.map(item => (
                        <ListGroup.Item key={item.id} className="news-item">
                            <div className="d-flex justify-content-between align-items-start">
                                <Badge bg={item.sentimentColor} className="sentiment-badge">{item.sentiment}</Badge>
                                <div className="ms-3 me-auto news-data">
                                    <div className="fw-bold news-title" onClick={() => handleClickOpen(item)}>{item.title}</div>
                                    <div className="news-description mt-1 mb-1">{item.description}</div>

                                    <div className="news-source text-end">
                                        <small>{item.source} </small>
                                        <small>|</small>
                                        <small> {item.time}</small>
                                    </div>
                                </div>
                            </div>

                        </ListGroup.Item>
                    ))}
                </ListGroup>

                <Dialog
                    maxWidth="sm"
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0 dialog-p">News</p>
                            <div>
                                <img style={{ color: '#898889', height: '24px' }}  src={`${process.env.PUBLIC_URL}/images/bookmark-gray.png`} alt="bookmark-icon" />
                                <img style={{ color: '#898889', height: '26px', marginLeft:'3px', marginRight: '3px' }}  src={`${process.env.PUBLIC_URL}/images/Hide.png`} alt="bookmark-icon" />
                                <img style={{ float: 'none' }} src={`${process.env.PUBLIC_URL}/images/close.png`} alt="close" className="close-icon" role="button" onClick={handleClose} />
                            </div>
                        </div>
                        {selectedNews ? selectedNews.title : 'News Details'}
                    </DialogTitle>
                    <DialogContent className="scrollbar-custom">
                        <DialogContentText>
                            <div className="d-flex align-items-center">
                                {selectedNews && (
                                    <Badge bg={selectedNews.sentimentColor} className="dialog-badges">
                                        {selectedNews.sentiment}
                                    </Badge>
                                )}
                                <div className="news-source-dialog ms-3">
                                    <small>{selectedNews ? selectedNews.source : 'NA'} </small>
                                    <small>|</small>
                                    <small> {selectedNews ? selectedNews.posted_by : 'NA'}</small>
                                    <small>|</small>
                                    <small> {selectedNews ? selectedNews.posted_date : 'NA'}</small>
                                    <small>,</small>
                                    <small> {selectedNews ? selectedNews.updated_on : 'NA'}</small>
                                </div>
                            </div>

                            <div className="blue-div mt-3 mb-3">
                                <p className="mb-0 ai_summary">AI Summary</p>
                                <p className="mb-0">• Gold shed 3.5% on Friday, the most since November 2020</p>
                                <p className="mb-0">• US CPI data due on Wednesday</p>
                                <p className="mb-0">• Federal Reserve policy meeting starts on Tuesday</p>
                                <p className="mb-0">• China buying is a key market focus, analyst says</p>
                            </div>
                            
                            <span>June 10 (Reuters) - Gold prices rebounded on Monday after the precious metal's biggest daily drop in three and a half years in the last session, as investors awaited U.S. inflation data and the Federal Reserve's decision on interest rates later this week.</span><br></br>
                            <span>Spot gold was up 0.8% at $2,310.81 per ounce as of 1817 GMT. U.S. gold futures settled about 0.1% higher at $2,327.</span><br></br>
                            <span>The sell-off on Friday seemed a bit excessive and "bargain hunters are surfacing at this lower price point," said Phillip Streible, chief market strategist at Blue Line Futures. "There's so much data and so many events coming out ... so there's going to be more volatility and more fireworks this week." Bullion lost about $83 an ounce on Friday, declining 3.5% in its biggest one-day drop since November 2020 after a stronger-than-expected U.S. jobs report dented hopes for a September interest rate cut and reports that China's central bank was holding off gold purchases put off investors betting on Chinese demand. "People's Bank of China (PBOC) has never been a constant buyer. There have been distinct phases of buying followed by multi-month breaks. But as long as the PBOC doesn't resume buying, gold prices could trade sideways because the China buying topic is a key market focus," said Carsten Menke, an analyst at Julius Baer. Gold's tentative recovery occurred despite a rise in the dollar and U.S. Treasury yields, with the market's focus shifting to the release of the U.S. consumer price index report on Wednesday, the same day as the Fed's policy decision. The U.S. central bank is not expected to make any change to its policy rate this week, but the focus will be on policymakers' updated economic projections and Fed Chair Jerome Powell's news conference after the end of the two-day meeting. Higher rates increase the opportunity cost of holding non-yielding bullion. Spot silver rose 1.9% to $29.72 per ounce and platinum was up 0.8% at $973.60, while palladium fell about 0.9% to $904.25.</span>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Card.Body>
        </Card>
    );
}

export default NewsFeed;
