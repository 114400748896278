import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState({
        accessToken: localStorage.getItem('accessToken') || null,
        refreshToken: localStorage.getItem('refreshToken') || null,
        userId: localStorage.getItem('userId') || null,
    });
    const [userData, setUserData] = useState(null);
    const [selectedValue, setSelectedValue] = useState('ZS-Jan25');
    const [selectedValueAnalysis, setSelectedValueAnalysis] = useState([]);
    const [symbolData, setSymbolData] = useState('');
    const [openSettings, setOpenSettings] = useState(false);
    const [currentDate, setCurrentDate] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [symbolTradeDate, setSymbolTradeDate] = useState(null);
    const handleOpenSettings = () => setOpenSettings(true);
    const handleCloseSettings = () => setOpenSettings(false);
    const [tradeData, setTradeData] = useState([]);
    const [recentTransactionsData, setRecentTransactionsData] = useState([]);
    const settingParams = {}; 
    const [accountInfoData, setaccountInfoData] = useState({});
    const [watchlistData, setWatchlistData] = useState();
    const [perfomanceChartData, setPerfomanceChartData] = useState([]);
    const [yourHoldingData, setYourHoldingData] = useState([]);
    const [preferenceData, setPreferenceData] = useState([]);
    const [aioperation, setAiOperation] = useState(1.25);
    const [symbolDetails, setSymbolDetails] = useState([]);
    const [tradingviewsymbol, setTradingviewSymbol] = useState('ZC1!'); 
    const token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    const [selectedNameVariable, setSelectedNameVariable] = useState(null);
    const [availableBalance, setAvailableBalance] = useState();
    const [bidAskData, setBidAskData] = useState();
    const [priceChange, setPriceChange] = useState();
    const [currentPrice, setCurrentPrice] = useState();
    const [highPrice, sethighPrice] = useState();
    const [lowPrice, setlowPrice] = useState();
    const [wasdeData, setWasdeData] = useState(null);
    const [latestWasdeData, setLatestWasdeData] = useState(null);
    const [cropconditionData, setCropConditionData] = useState(null);
    const [cropstage, setCropStage] = useState('Corn Planted');  // Add this line

    const [latestConid, setLatestConid] = useState(null);
    const [olChange, setOlChange] = useState(null);
    const [aiPl, setAiPl] = useState(null);
    const [finalData, setFinalData] = useState([
        { symbol: 'ZS-Jan25', full_name: 'Soybean', exchange: 'CBOT', type: 'commodity', conid: '597391665'}
    ])
    const [watchListLoading, setWatchListLoading] = useState(true);
    const [bidLoading, setBidLoading] = useState(true);
    const [symbolDetailsLoading, setSymbolDetailsLoading] = useState(true);
    const [recentTransLoading, setRecentTransLoading] = useState(true);
    const [allocationPrefLoading, setAllocationPrefLoading] = useState(true);

    const login = (accessToken, refreshToken, userId) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userId', userId);
        setAuth({ accessToken, refreshToken, userId });
        navigate('/Home/Landing');
    };

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        setAuth({ accessToken: null, refreshToken: null, userId:null });
        navigate('/');
    };

    useEffect(() => {
        if (Array.isArray(wasdeData) && wasdeData.length > 0) {
            const latestItem = wasdeData[wasdeData.length - 1];
            setLatestWasdeData(latestItem); // Set latest item
            
            console.log(latestItem);
            
            if (latestItem && latestItem.conid) {
                fetchLiveMarketDataWithWasde(latestItem.conid); // Fetch live market data
                getPriceChange(latestItem.conid)
                getOlChange(latestItem.conid);
            }
        } else {
            setLatestWasdeData(null); // Reset if no data
        }
    }, [wasdeData]);

    // Function to fetch user data
    const fetchUserData = async () => {
        if (userData) return userData; // Use existing data if already fetched
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${auth.userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                },
            });
            setUserData(response.data);
            if (response.data.ai_settings && response.data.ai_settings.ai_operation) {
                setAiOperation(response.data.ai_settings.ai_operation);  // Set ai_operation from userData
            }
            return response.data;
        } catch (error) {
            console.error("Error fetching user data:", error);
            if (error.response?.status === 401) logout(); // Handle token expiration
        }
    };

    const account = useCallback(async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/accounts-api')
            // console.log(response);
            return response.data;
        }
        catch(error){
            console.error("Error fetching user data:", error);
        }
    }, []);

    const initEmailChange = async (newemail) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/init-email-change`, 
                { email: newemail },
                { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            return response.data; // Return the response data on success
        } catch (error) {
            console.error("Error initiating email change:", error); // Updated log message
            // if (error.response?.status === 401) {
            //     logout(); // Handle token expiration
            // }
            return null; // Return null or an error object for consistency
        }
    };

    const changeEmail = async (otp,newemail) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/change-email`, 
                { otp: otp, newEmail: newemail, userId: auth.userId },
                { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            return response.data; // Return the response data on success
        } catch (error) {
            console.error("Error initiating email change:", error); // Updated log message
            if (error.response?.status === 401) {
                // logout(); // Handle token expiration
            }
            return null; // Return null or an error object for consistency
        }
    };

    const getWatchLists = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/getWatchLists')
            setWatchlistData(response.data);
            // console.log(response);
            // return response.data;
            if(response.status === 200){
                setWatchListLoading(false);
            }
        }
        catch(error){
            console.error("Error fetching user data:", error);
            setOpenSettings(true); 
            setWatchListLoading(false);
        }
    };

    const getOrderData = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/orders')
            setRecentTransactionsData(response.data.orders);
            // console.log(response.data.orders);
            // return response.orders;
            if(response.status === 200){
                setRecentTransLoading(false);
            }
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
            setRecentTransLoading(false);
        }
    };

    const getRecentTransactions = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/trades')
            setTradeData(response.data);
            // console.log(response.data.orders);
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    const createOrder = async (quantity, side, ordertype, cashqty) => {
        try {
            const orderData = {
                orders: [
                    {
                        conid: parseInt(latestConid),
                        orderType: ordertype,
                        side: side,
                        tif: "GTC",
                        manualIndicator: false
                    }
                ]
            };
            if (quantity) {
                orderData.orders[0].quantity = quantity;
            }
            if (cashqty) {
                orderData.orders[0].cashqty = cashqty;
            }
            // console.log(orderData);
            const response = await axios.post(
                "https://ibkrapi.nodojicommodities.com/custom-apis/createOrder",
                orderData
            );
            return response.data;
        } catch (error) {
            console.error("Error in createOrder:", error);
        }
    };
    
    const addWatchlist = async (id, name, rows) => {
        try {
            const response = await axios.post(
                "https://ibkrapi.nodojicommodities.com/custom-apis/addWatchlist", 
                { id: id, name: name, rows: rows }
            );
            return response.data;
        } catch (error) {
            console.error("Error for addWatchlist:", error);
            
        }
    };

    const deleteWatchlist = async (id) => {
        try {
            const response = await axios.delete(
                `https://ibkrapi.nodojicommodities.com/custom-apis/deleteWatchlist?id=${id}`, 
            );
            return response.data;
        } catch (error) {
            console.error("Error for addWatchlist:", error);
            
        }
    };

    const aiSettings = async(ai_settings) => {
        try{
            await axios.put(
                `${process.env.REACT_APP_API_URL}/user/${userId}`, // Replace with your actual API endpoint
                { ai_settings },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
        }catch (error) {
            console.error("Error for addWatchlist:", error);
        }
    };

    const getSymbolTradeDate = useCallback(async () => {
        try {
            const response = await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/getSymbolDetails', {
                params: {
                    symbol: selectedNameVariable,
                    secType: "FUT",
                },
            });
            setSymbolTradeDate(response.data);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }, [selectedNameVariable]);

    useEffect(() => {
        // console.log('called')
        // console.log(selectedNameVariable)
        if (selectedNameVariable) {
            getSymbolTradeDate();
        }
    }, [selectedNameVariable, getSymbolTradeDate]);

    const fetchSymbolDetails = async (symbol) => {
        try {
            const response = await axios.get(
                'https://ibkrapi.nodojicommodities.com/custom-apis/getSymbolDetails',
                {
                    params: {
                        symbol: symbol,
                        secType: "FUT",
                    },
                }
            );
            return response.data; // Return the API response
        } catch (error) {
            console.error("Error fetching symbol details:", error);
            throw error; // Optionally rethrow the error to handle it in the caller function
        }
    };

    // const accountsDetails = async () => {
    //     try {
    //         const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/accounts-details');
    //         // setaccountInfoData(response.data);
    //         // console.log(accountInfoData);
    //         // return response.orders;
    //     }
    //     catch(error){
    //         console.error("Error fetching user data:", error);
    //         // setOpenSettings(true); 
    //     }
    // };

    const accountsSummary = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/accountSummary');
            setaccountInfoData(response.data);
            // console.log(response.data);
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    const holdingsData = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/holdings-new');
            setYourHoldingData(response.data.holdings);
            // console.log(response.data);
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    // https://ibkrapi.nodojicommodities.com/custom-apis/account-performance-categorized
    const preformanceData = useCallback(async (new_interval, startDate, endDate) => {
        try {
            const response =  await axios.post('https://ibkrapi.nodojicommodities.com/custom-apis/account-performance-categorized',
                {params: {
                    interval: new_interval,
                    start_date: startDate,
                    end_date: endDate
                }
            });
            setPerfomanceChartData(response.data)
            // console.log("perfomanceChartData: "+ perfomanceChartData)
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    }, []);

    const allocations = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/allocations`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                },
            });

            setPreferenceData(response.data);

            if (response.status === 200) {
                setAllocationPrefLoading(false);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setAllocationPrefLoading(false);
            return null;
        }
    }, [auth.accessToken]);

    const editAllocations = async (name, date, position, amount, id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/allocations/${id}`, 
                {name: name, date:date, position:position, amount:amount},
                {headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            // console.log();
            
            if (response.status === 200) {
                // console.log("Allocation updated successfully", response.data);
    
                // Call the get API to fetch the updated allocations
                await allocations(); // Assuming you have a function to get allocations
            }
            return response;
        } catch (error) {
            console.error("Error fetching user data:", error);
            // if (error.response?.status === 401) logout(); // Handle token expiration
            return null;
        }
    };

    const addAllocations = async (name, date, position, amount) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/allocations`, 
                {name: name, date:date, position:position, amount:amount},
                {headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            if (response.status === 200 || response.status === 201) {
                // console.log("Allocation created successfully", response.data);
    
                // Call the get API to fetch the updated allocations
                await allocations(); // Assuming you have a function to get allocations
            }

            return response;
        } catch (error) {
            console.error("Error fetching user data:", error);
            // if (error.response?.status === 401) logout(); // Handle token expiration
            return null;
        }
    };

    const deleteAllocations = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/allocations/${id}`, 
                {headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            if (response.status === 200 || response.status === 201) {
                // console.log("Allocation deleted successfully", response.data);
    
                // Call the get API to fetch the updated allocations
                await allocations(); // Assuming you have a function to get allocations
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            // if (error.response?.status === 401) logout(); // Handle token expiration
            return null;
        }
    };

    const trading_historical_data = async (symbol, from, to, schema) => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/get-historical-data',
                {params: {
                    start_time_str : from,
                    end_time_str : to,
                    schema : schema,
                    symbol : symbol
                }
            });
            // console.log("tradingHistoricalData: "+ response.data?.historical_data)
            return response.data?.historical_data;
        }
        catch(error){
            console.error("Error fetching trading_historical_data data:", error);
            // setOpenSettings(true); 
        }
    };

    const trading_live_data = async (symbol) => {
        try {
          const response = await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/get-live-data', {
            params: {
              schema: 'ohlcv-1s',
              symbol: symbol,
            },
          });
          if (response.data && response.data && response.data.live_data.length > 0) {
            return { data: response.data.live_data };
          } else {
            // console.warn('No live data received for symbol:', symbol);
            return null;
          }
        } catch (error) {
          console.error("Error fetching live data:", error);
          return null;
        }
    };


    const getWasde_data = useCallback(
        async (year = new Date().getFullYear(), cropType = 'corn') => {
          const params = new URLSearchParams();
          if (year) params.append('year', year);
          if (cropType) params.append('cropType', cropType);
    
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/wasde-reports?${params.toString()}`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${auth.accessToken}`,
                }
              }
            );
            setWasdeData(response.data); // Update latestWasdeData in AuthContext
          } catch (error) {
            console.error('Error fetching WASDE data:', error.message);
            // throw error;
            return null;
          }
        },
        [auth.accessToken] // Dependencies: stabilize function based on accessToken
    );
    

    const supportEmail = async (title, description) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/support-email`, 
                { 
                    email: 'Taylor@NoDojiCommodities.com',
                    title: title,
                    description: description
                }
            );
            return response.data; // Return the response data on success
        } catch (error) {
            console.error("Error supportEmail:", error); // Updated log message
            return null; // Return null or an error object for consistency
        }
    };
      
    const bid_ask_data = async (symbol) => {
        setBidLoading(true);
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/latest_bid_ask',
                {params: {
                    symbol : symbol,
                    dataset : 'GLBX.MDP3'
                }
            });
            setBidAskData(response.data?.asks_and_bids)
            if(response.status === 200){
                setBidLoading(false);
            }
        }
        catch(error){
            console.error("Error fetching asks_and_bids data:", error);
            // setOpenSettings(true); 
            setBidLoading(false);
        }
    };

    // const hasFetchedData = useRef(false);

    // useEffect(() => {
    //     if (location.pathname === '/analyze' && !hasFetchedData.current) {
    //         getWasde_data();
    //         hasFetchedData.current = true;  // Prevent further calls
    //     }
    // }, [location.pathname, auth.accessToken]);


    const fetchLiveMarketDataWithWasde = async (conid) => {
        
        console.log('Fetched Conid:', conid);
        try{
            const response = await axios.get(`https://ibkrapi.nodojicommodities.com/custom-apis/get-live-market-data?conids_str=${conid}`);
            console.log("DATA FROM FETCHING CONID:", response.data);
            setSelectedValueAnalysis(response.data);
            return response.data;
        }
        catch(error){
            console.error("Error fetching getLiveMarketData", error);
        }
    }

    const getLiveMarketData = async (symbol) => {
        try{
            const response = await axios.get(`https://ibkrapi.nodojicommodities.com/custom-apis/get-live-market-data?conids_str=${symbol}`);
            setSymbolDetails(response.data);
            if(response.status === 200){
                setSymbolDetailsLoading(false);
            }
            return response.data;
        }
        catch(error){
            console.error("Error fetching getLiveMarketData", error);
            setSymbolDetailsLoading(false); 
        }
    }

    const getHistoricalMarketData = async (schema,period, conid, time) => {
        try {
            const params = {
              conid: conid,
              bar: schema,
              period: period,
            };
            if (time) {
              params.startTime = time;
            }
            const response = await axios.get(
              `https://ibkrapi.nodojicommodities.com/custom-apis/get-historical-market-data-new`,
              { params }
            );
            
            return response.data;
          } catch (error) {
            console.error("Error fetching historical-market-data:", error);
          }
    }

    const getPriceChange = async (conid) => {
        try{
            const response = await axios.get(`https://ibkrapi.nodojicommodities.com/custom-apis/get-price-changes?conid=${conid}`);
            // console.log(response.data);
            setPriceChange(response.data.price_changes);
        }
        catch(e){
            console.log("Error getPriceChange :", e);
        }
    }

    const getOlChange = async (conid) => {
        try{
            const response = await axios.get(`https://ibkrapi.nodojicommodities.com/custom-apis/get-last-ol-value?conid=${conid}`);
            setOlChange(response.data?.ol_value);
        }
        catch(e){
            console.log("Error getPriceChange :", e);
        }
    }

    const getAiPl = async () => {
        try{
            const response = await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/get-ai-pl');
            console.log(response.data);
            setAiPl(response.data);
        }
        catch(e){
            console.log("Error getPriceChange :", e);
        }
    }

    const getCropCondition = async (year = 2024, cropcondition = 'Corn Condition', state = '18 Sts') => {
        try {
            const url = `https://newwebapi.nodojicommodities.com/usda-condition-reports?year=${year}&cropType=${cropcondition}&state=${state}`;
            
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching crop condition data:', error.message);
            return null;
        }
    };

    const getCropProgress = async (year = 2024, cropstage = 'Corn Planted', state = '18 States') => {
        try {
            const url = `https://newwebapi.nodojicommodities.com/usda-progress-reports?year=${year}&cropStage=${cropstage}&state=${state}`

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                }
                
            });
            return response.data;
        }
        catch (error) {
            console.error('Error fetching crop progress data:', error.message);
            return null;
        }
    }
    
    const getAiPnlResults = async(interval) => {
        try{
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pnl-results?date_range=${interval}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.accessToken}`,
                    }
                }
            );
            return response.data;
        }catch(error){
            console.error('Error fetching AiPnl data:', error.message);
            return null;
        }
    }

    useEffect(() => {
        // Get and format the current date
        const date = new Date();
        const formattedDate = date.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
        setCurrentDate(formattedDate);

        // Get and format the current time in 24-hour format based on user's timezone
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        setCurrentTime(formattedTime);

        allocations();
        getWatchLists();
        getOrderData();
        getRecentTransactions();
        holdingsData();
        preformanceData();
        accountsSummary();
        getAiPl();
    }, [allocations,preformanceData])

    return (
        <AuthContext.Provider value={{ auth, login, logout, fetchUserData, userData, initEmailChange, changeEmail, selectedValue, setSelectedValue, currentDate, currentTime, recentTransactionsData, accountInfoData, openSettings, handleOpenSettings, handleCloseSettings, setOpenSettings, watchlistData, yourHoldingData, aioperation, setAiOperation, account, createOrder, addWatchlist, deleteWatchlist, getWatchLists, aiSettings, preformanceData, tradeData, preferenceData, symbolDetails, editAllocations, addAllocations, settingParams, symbolTradeDate, setSelectedNameVariable, getSymbolTradeDate, fetchSymbolDetails, deleteAllocations, perfomanceChartData, trading_historical_data, trading_live_data, availableBalance, setAvailableBalance, supportEmail, latestWasdeData, getWasde_data, getCropCondition, getCropProgress, getLiveMarketData, getHistoricalMarketData, getPriceChange, priceChange, bid_ask_data, bidAskData, currentPrice, setCurrentPrice, selectedValueAnalysis, setSelectedValueAnalysis, tradingviewsymbol, setTradingviewSymbol ,setLatestConid, finalData, setFinalData, getOrderData, watchListLoading, bidLoading, symbolDetailsLoading, recentTransLoading, allocationPrefLoading, setAllocationPrefLoading, symbolData, setSymbolData, olChange, getOlChange, aiPl, highPrice, sethighPrice, lowPrice, setlowPrice, getAiPnlResults }}>
            {children}
        </AuthContext.Provider>
    );
};
