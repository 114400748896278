import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContext';
import Skeleton from 'react-loading-skeleton';

function CropOverView() {

    const { currentDate, currentTime, latestWasdeData  } = useContext(AuthContext);

    const renderSkeleton = () => (
        <div>
          <Card className="main-card mt-3 card-large">
            <Card.Header className="pb-0">
              <div className="d-flex justify-content-between mt-1">
                <h2 className="medium-heading mb-0">
                  <Skeleton width={200} height={20} 
                    style={{ margin: '4px 2px', borderRadius: '3px'}}
                    baseColor="rgba(137, 136, 137, 0.3)"
                    highlightColor="rgba(176, 176, 176, 0.3)"
                  />
                </h2>
                <Skeleton circle width={30} height={30} 
                    style={{ margin: '4px 2px', borderRadius: '3px'}}
                    baseColor="rgba(137, 136, 137, 0.3)"
                    highlightColor="rgba(176, 176, 176, 0.3)"
                />
              </div>
              <span className="small-gray">
                <Skeleton width={150} height={15} 
                    style={{ margin: '4px 2px', borderRadius: '3px'}}
                    baseColor="rgba(137, 136, 137, 0.3)"
                    highlightColor="rgba(176, 176, 176, 0.3)"
                    />
              </span>
            </Card.Header>
            <Card.Body className="pt-2">
              <Skeleton width={100} height={20} className="sub-header" 
                style={{ margin: '4px 2px', borderRadius: '3px'}}
                baseColor="rgba(137, 136, 137, 0.3)"
                highlightColor="rgba(176, 176, 176, 0.3)"
                />
              <Skeleton width="100%" height={80} 
                style={{ margin: '4px 2px', borderRadius: '3px'}}
                baseColor="rgba(137, 136, 137, 0.3)"
                highlightColor="rgba(176, 176, 176, 0.3)"
                />
              <Skeleton width={100} height={20} className="sub-header mt-3" 
                style={{ margin: '4px 2px', borderRadius: '3px'}}
                baseColor="rgba(137, 136, 137, 0.3)"
                highlightColor="rgba(176, 176, 176, 0.3)"
                />
              <Skeleton width="100%" height={80} 
                style={{ margin: '4px 2px', borderRadius: '3px'}}
                baseColor="rgba(137, 136, 137, 0.3)"
                highlightColor="rgba(176, 176, 176, 0.3)"
                />
              <hr className="mt-2 mb-2" />
              <Skeleton width={150} height={20} className="sub-header" 
                style={{ margin: '4px 2px', borderRadius: '3px'}}
                baseColor="rgba(137, 136, 137, 0.3)"
                highlightColor="rgba(176, 176, 176, 0.3)"
                />
              <div>
                {[...Array(4)].map((_, index) => (
                  <div key={index} className="mt-2">
                    <Skeleton width="70%" height={15} 
                        style={{ margin: '4px 2px', borderRadius: '3px'}}
                        baseColor="rgba(137, 136, 137, 0.3)"
                        highlightColor="rgba(176, 176, 176, 0.3)"
                    />
                    <Skeleton width="100%" height={10} 
                        style={{ margin: '4px 2px', borderRadius: '3px'}}
                        baseColor="rgba(137, 136, 137, 0.3)"
                        highlightColor="rgba(176, 176, 176, 0.3)"
                    />
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    
      if (!latestWasdeData) {
        return renderSkeleton();
      }

    if (!latestWasdeData) {
        return <div>Loading...</div>;
    }

    const changePercentageAcres = latestWasdeData?.cy_ac_planted && latestWasdeData?.ly_ac_planted ? ((latestWasdeData.cy_ac_planted - latestWasdeData.ly_ac_planted)/latestWasdeData.ly_ac_planted * 100).toFixed(2) : "N/A";
    const changePercentageYield = latestWasdeData?.cy_yield && latestWasdeData?.ly_yield ? ((latestWasdeData.cy_yield - latestWasdeData.ly_yield)/latestWasdeData.ly_yield * 100).toFixed(2) : "N/A";
    const maxValue = 17000; // Maximum value for 100%
    const stocklatestWasdeData = [
        {
            label: "Beginning Stocks",
            sublabel: "Beginning Stocks",
            value: latestWasdeData?.cy_beg_stock,
            color: "#317CFF"
        },
        {
            label: "Supply",
            sublabel: "Production",
            value: latestWasdeData?.cy_production,
            color: "#40C796",
        },
        {
            label: "Use",
            sublabel: "Demand",
            value: latestWasdeData?.cy_use,
            color: "#E04662",
        },
        {
            label: "Ending Stocks",
            sublabel: "Ending Stocks",
            value: latestWasdeData?.cy_end_stock,
            color: "#A8A8A8",
        },
    ]

    const percentage = (value) => {
       return (value/maxValue) * 100;
    }

    // Function to format the change with sign and color
    const formatChange = (value) => {
        if (value === "N/A") return value;
        const formattedValue = parseFloat(value);
        const color = formattedValue >= 0 ? '#40C796' : 'red';
        const sign = formattedValue >= 0 ? '+' : '';
        return <span style={{ color }}>{sign}{formattedValue}%</span>;
    };
    
    return (
        <>
            <Card className="main-card mt-3 card-large">
                <Card.Header className="pb-0">
                    <div className="d-flex justify-content-between mt-1">
                        <h2 className="medium-heading mb-0">{latestWasdeData.report_type.charAt(0).toUpperCase() + latestWasdeData.report_type.slice(1)} Overview</h2>
                    </div>
                    <span className="small-gray">{currentDate} | {currentTime}</span>
                </Card.Header>
                <Card.Body className="pt-2">
                    <div>
                        <div className='sub-header'>Acres (Million Acres)</div>
                        <table class="acres-table">
                            <thead>
                                <tr>
                                    <th>This year</th>
                                    <th>Last year</th>
                                    <th>Change%</th>
                                    <th>5 year avg.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{ latestWasdeData.cy_ac_planted?.toFixed(2) || 'N/A'}</td>
                                    <td>{ latestWasdeData.ly_ac_planted?.toFixed(2) || 'N/A'}</td>
                                    <td>{ formatChange(changePercentageAcres) }</td>
                                    <td>{ latestWasdeData.averages.avgAcPlanted?.toFixed(2) || 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div class="pt-3">
                        <div className='sub-header'>Yield (Bushels)</div>
                        <table class="acres-table">
                            <thead>
                                <tr>
                                    <th>This year</th>
                                    <th>Last year</th>
                                    <th>Change%</th>
                                    <th>5 year avg.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{ latestWasdeData.cy_yield?.toFixed(2) || 'N/A'}</td>
                                    <td>{ latestWasdeData.ly_yield?.toFixed(2) || 'N/A'}</td>
                                    <td>{ formatChange(changePercentageYield) }</td>
                                    <td>{ latestWasdeData.averages.avgYield?.toFixed(2) || 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr className="mt-3 mb-3" />
                    <div className='sub-header' style={{ marginBottom: "10px"}}>Supply / Demand Details </div>
                    { stocklatestWasdeData.map((stock, index) => (
                        <div className="stock-bar-container" key={index}>
                            <div className="gray-text-1">{stock.label}</div>
                            <div className="bar-container">
                                <div className="progress-container">
                                    <div
                                        className="progress-bar"
                                        style={{
                                            width: `${percentage(stock.value)}%`,
                                            backgroundColor: stock.color
                                        }}
                                    ></div>
                                </div>
                                <div className="supply-details">
                                    <div className="text-circle-gap">
                                        <div
                                            className="circle-beginning"
                                            style={{ backgroundColor: stock.color }}
                                        ></div>
                                        <div className="gray-text-2">{stock.sublabel}</div>
                                    </div>
                                    <div className="value-text-1">{stock.value}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Card.Body>
            </Card>
        </>
    )
}

export default CropOverView;