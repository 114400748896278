import React, { useState } from "react";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { Container, Row, Col } from 'react-bootstrap';

const PricingPlans = () => {
  const [planType, setPlanType] = useState("monthly");

  const handlePlanTypeChange = (event, newPlanType) => {
    if (newPlanType) {
      setPlanType(newPlanType);
    }
  };

  const plans = [
    {
      title: "Basic",
      price: "Free",
      features: ["3 Customizable Pages", "100+ Indicators"],
    },
    {
      title: "Foundation",
      price: planType === "monthly" ? "$150 / per month" : "$1500 / per year",
      features: [
        "Connect Up to 3 Accounts",
        "3 Customizable Pages",
        "100+ Indicators",
        "In-Platform Alerts",
        "Client Communication",
        "Create Custom Reports Tailored",
      ],
    },
    {
      title: "Professional",
      price: planType === "monthly" ? "$300 / per month" : "$3000 / per year",
      features: [
        "Connect Up to 10 Accounts",
        "10 Customizable Pages",
        "100+ Indicators",
        "Custom Indicators",
        "AI Assistant",
        "SMS / Email Alerts",
        "Priority Customer Service",
      ],
    },
    {
      title: "Expert",
      price: planType === "monthly" ? "$500 / per month" : "$5000 / per year",
      features: [
        "Connect Unlimited Accounts",
        "Unlimited Customizable Pages",
        "100+ Indicators",
        "Custom Indicators",
        "AI Assistant",
        "SMS / Email Alerts",
        "Dedicated Customer",
        "Support Agent",
      ],
    },
  ];

  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #3a3a3a, #181818)",
        color: "white",
        py: 5,
      }}
    >
      <Container maxWidth="lg">
        <Card className="empty-card">
          <Typography variant="h3" align="center" sx={{ mb: 2 }}>
            Choose your right plan!
          </Typography>
          <Row>
            <Col lg={3}></Col>
            <Col lg={6}>
              <Typography variant="h6" align="center" sx={{ mb: 3 }}>
                Select from the best plans, ensuring a perfect match. Need more or
                less? Customize your subscription for a seamless fit!
              </Typography>
            </Col>
          </Row>

        </Card>
        {/* Toggle Button for Plan Type */}
        <Box className="d-flex justify-content-center">
          <Card className="empty-card" style={{ width: "fit-content", borderRadius: "0px !important" }}>
            <ToggleButtonGroup
              value={planType}
              exclusive
              onChange={handlePlanTypeChange}
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 4,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: 2,
              }}
            >
              <ToggleButton
                value="monthly"
                sx={{
                  color: "white",
                  "&.Mui-selected": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
                }}
              >
                Monthly
              </ToggleButton>
              <ToggleButton
                value="annually"
                sx={{
                  color: "white",
                  "&.Mui-selected": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
                }}
              >
                Annually
              </ToggleButton>
            </ToggleButtonGroup>
          </Card>
        </Box>


        {/* Plan Cards */}
        <Grid container spacing={4}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
              className="payment-card"
                sx={{
                  height: "100%",
                  borderRadius: 2,
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CardContent sx={{paddingBottom: "0px"}}>
                  <Typography variant="h6" gutterBottom>
                    {plan.title}
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ fontWeight: "bold", paddingLeft: "15px" }}
                  >
                    <Box component="span">
                      {plan.price.split(" ")[0]}
                    </Box>
                    <Box component="span" sx={{ fontWeight: "normal" }} className="price-feature">
                      {` ${plan.price.split(" ").slice(1).join(" ")}`}
                    </Box>
                  </Typography>
                  <hr />
                  <ul style={{ padding: 0, paddingLeft: "15px", listStyle: "none" }}>
                    {plan.features.map((feature, i) => (
                      <li key={i} style={{ marginBottom: "8px" }} className="price-feature">
                        <img src={`${process.env.PUBLIC_URL}/images/grammerly.svg`} alt="user-img" /> {feature}
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <Button
                  className="price-submit"
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2,
                    mx: "auto",
                    width: "80%",
                    backgroundColor: "#48494C",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#5A5B5F",
                    },
                  }}
                >
                  Get Started
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default PricingPlans;
